import React, { createContext, useState, useContext, useEffect } from 'react';
import { onSnapshot, doc, collection, query, where, getDoc } from "firebase/firestore";
import { db } from "./firebase";

const initialUserData = {
    uid: "",
    email: "",
    name: "Laster ..",
    profileImage: "",
    cart: [],
    orderHistory:[{
        orderId: ""
    }],
    lastOrder: [{
        headline: "Laster"
    }], 
}

export const DataContext = createContext();

export const useData = () => useContext(DataContext);

export const DataProvider = ({ children }) => {
    const [userData, setUserData] = useState(initialUserData);
    const [userId, setUserId] = useState("");
    const [listings, setListings] = useState([]);
    const [recipes, setRecipes] = useState([]);

    useEffect(() => {
        if (userId) {
            const unsubscribeUser = fetchUserData(userId);
            const unsubscribeListings = fetchListings(userId);
            const unsubscribeRecipes = fetchRecipes(userId);
            return () => {
                unsubscribeUser();
                unsubscribeListings();
                unsubscribeRecipes();
            };
        }
    }, [userId]);

    const fetchUserData = (id) => {
        const docRef = doc(db, "users", id);

        const unsubscribe = onSnapshot(docRef, async (docSnapshot) => {
            if (docSnapshot.exists()) {
                const userDocData = docSnapshot.data();
                setUserData(userDocData);
                userDocData.admin?localStorage.setItem("admin", "true"):localStorage.setItem("admin", "false");
                userDocData.creator?localStorage.setItem("creator", "true"):localStorage.setItem("creator", "false");

                // Hent lastOrder basert på siste orderId i orderHistory
                if (userDocData.orderHistory && userDocData.orderHistory.length > 0) {
                    const lastOrderHistory = userDocData.orderHistory[userDocData.orderHistory.length - 1];
                    const lastOrderRef = doc(db, "sales", lastOrderHistory.orderId);
                    const lastOrderDoc = await getDoc(lastOrderRef);
                    if (lastOrderDoc.exists()) {
                        setUserData((prevUserData) => ({
                            ...prevUserData,
                            lastOrder: lastOrderDoc.data(),
                        }));
                    }
                }
            } else {
                console.log("Brukerdokumentet finnes ikke");
                setUserData(initialUserData);
            }
        });

        return unsubscribe;
    };

    const fetchListings = (userId) => {
        const q = query(collection(db, "listings"), where("creatorId", "==", userId));
        
        const unsubscribe = onSnapshot(q, (querySnapshot) => {
            const listingsData = [];
            querySnapshot.forEach((doc) => {
                listingsData.push({
                    id: doc.id,
                    ...doc.data()
                });
            });
            setListings(listingsData);
        });

        return unsubscribe;
    };

    const fetchRecipes = (userId) => {
        const q = query(collection(db, "recipes"), where("creatorId", "==", userId));
        
        const unsubscribe = onSnapshot(q, (querySnapshot) => {
            const recipesData = [];
            querySnapshot.forEach((doc) => {
                recipesData.push({
                    id: doc.id,
                    ...doc.data()
                });
            });
            setRecipes(recipesData);
        });

        return unsubscribe;
    };


    return (
        <DataContext.Provider value={{
            userData, setUserData, userId, setUserId, listings, recipes
        }}>
            {children}
        </DataContext.Provider>
    );
};