import sowIcon from '../../images/icons/UseLess Icon Needle - Brown.png'
import React, { useState, useEffect } from 'react';
import { onSnapshot, collection, query, where } from "firebase/firestore";
import { db } from "../../firebase";
import RecipeCard from "../profile/recipe/RecipeCard";
import RecipeDetail from "../profile/recipe/RecipeDetail";
import Slider from "react-slick";

interface Recipe {
  id: string;
  title: string;
  description: string;
  price: number;
  // Legg til andre felt basert på din Firestore-dokumentstruktur
}

export default function SowSection() {


    const [selectedRecipe, setSelectedRecipe] = useState<Recipe | null>(null);
    const [isDetailOpen, setIsDetailOpen] = useState(false);
    const [recipes, setRecipes] = useState<Recipe[]>([]);

  
    useEffect(() => {
      const fetchRecipes = () => {
        const q = query(collection(db, "recipes"), where("isActive", "==", true));
        const unsubscribe = onSnapshot(q, (querySnapshot) => {
          const recipesData: Recipe[] = [];
          querySnapshot.forEach((doc) => {
            recipesData.push({
              id: doc.id,
              ...(doc.data() as Omit<Recipe, 'id'>)
            });
          });
          setRecipes(recipesData);
        });
  
        return unsubscribe;
      };
  
      const unsubscribe = fetchRecipes();
      return () => unsubscribe();
    }, []);
  
    const handleCardClick = (recipe: Recipe) => {
      setSelectedRecipe(recipe);
      setIsDetailOpen(true);
    };
  
    const handleCloseDetail = () => {
      setIsDetailOpen(false);
      setSelectedRecipe(null);
    };
    
// Oppdater innstillinger basert på antallet oppskrifter
const settings = {
  infinite: recipes.length > 3, // Bare aktiver infinite hvis det er mer enn 3 oppskrifter
  speed: 800,
  slidesToShow: Math.min(4, recipes.length), // Vis maks 4 oppskrifter, eller færre hvis det ikke er nok
  slidesToScroll: Math.min(4, recipes.length), // Scroll gjennom maks 4 oppskrifter, eller færre
  autoplay: recipes.length > 3, // Bare aktiver autoplay hvis det er mer enn 3 oppskrifter
  autoplaySpeed: 3000,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: Math.min(2, recipes.length), // Juster for responsivitet
        slidesToScroll: Math.min(2, recipes.length),
        infinite: recipes.length > 2,
        dots: true
      }
    },
    {
      breakpoint: 800,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: recipes.length > 1, // Bare aktiver infinite hvis det er mer enn 1 oppskrift
      }
    }
  ]
};

  return (
    <>
    <div className="bg-stone-50 px-6 py-12 sm:py-16 lg:px-8 flex">

      <div className="mx-auto max-w-2xl text-center">
        <p className="text-base font-semibold leading-7 text-indigo-600"></p>
        <h2 className="mt-2 text-4xl font-bold tracking-tight text-stone-900 sm:text-6xl">Se hele utvalget av symønstre!</h2>
        <p className="mt-6 text-lg leading-8 text-gray-600">
        Vil du gjøre det selv? Gå gjennom alle våre mønstre for å finne ditt neste sy prosjekt!
        </p>

      <div className="flex justify-center mt-8">
  
  <a
        className=" rounded-md bg-[#F36699] px-8 py-3 text-lg font-semibold text-white shadow-sm hover:bg-[#465745]"
        href="recipes"
      >
        Se alle
      </a>
</div>
</div>
<img src={sowIcon} className="w-14 h-14 sm:h-60 sm:w-60 mr-auto"/>

    </div>
    <div className="mb-10 mx-2">
    <Slider {...settings}>
  {recipes.map((recipe) => (
    <div className="p-0 sm:p-4 recipe-card" key={recipe.id}> {/* Bruk tilpasset klasse her */}
      <RecipeCard recipe={recipe} onCardClick={() => handleCardClick(recipe)} />
    </div>
  ))}
</Slider>
        </div>
              {isDetailOpen && <RecipeDetail recipe={selectedRecipe} onClose={handleCloseDetail} />}
    </>
  )
}
