import React, { useEffect, useState } from 'react';
import { collection, query, where, getDocs } from 'firebase/firestore';
import { db } from '../../firebase'; // Importer din Firestore-instans
import CreatorCard from '../creator/CreatorCard';
import CreatorApplicationList from '../creator/CreatorApplicationList';


interface CreatorApplication {
  age: string;
  creatorBio: string;
  imageURL: string;
  location: string;
  otherComments: string;
  reason: string;
  recipe: boolean;
  socialMedia: string;
  sow: boolean;
}

interface Creator {
  id: string;
  name: string;
  specialty: string;
  description: string;
  collections: string;
  imageSrc: string;
  imageAlt: string;
  creatorApplication: CreatorApplication; // Inkluderer CreatorApplication som en del av Creator-typen
}


interface CreatorCardProps {
  creator: Creator;
  onCardClick: (creator: Creator) => void;
}

  
  export default function Creators() {
    const [selectedCreator, setSelectedCreator] = useState<Creator | null>(null);
    const [isDetailOpen, setIsDetailOpen] = useState(false);
    const [creators, setCreators] = useState<Creator[]>([]);
    const [showApplications, setShowApplications] = useState(false);
  
    useEffect(() => {
        const fetchCreators = async () => {
          const q = query(collection(db, "users"), where("creator", "==", true));
          const querySnapshot = await getDocs(q);
          const fetchedCreators = querySnapshot.docs.map(doc => {
            const data = doc.data();
            // Sørg for at vi oppfyller CreatorApplication-kravene her
            const creatorApplication: CreatorApplication = {
              age: data.creatorApplication.age,
              creatorBio: data.creatorApplication.creatorBio,
              imageURL: data.creatorApplication.imageURL,
              location: data.creatorApplication.location,
              otherComments: data.creatorApplication.otherComments,
              reason: data.creatorApplication.reason,
              recipe: data.creatorApplication.recipe ?? false, // Bruk ?? operatoren for å håndtere undefined verdier
              socialMedia: data.creatorApplication.socialMedia,
              sow: data.creatorApplication.sow ?? false,
            };
            return {
              id: doc.id,
              name: data.name || "Ukjent",
              specialty: creatorApplication.reason || 'Ingen spesialitet oppgitt',
              description: data.bio || "Ingen beskrivelse tilgjengelig",
              collections: 'Ingen kolleksjoner oppgitt',
              imageSrc: data.profileImage || "fallback-image-url.jpg", // Fallback bilde-URL
              imageAlt: `${data.name || "Ukjent"}'s profilbilde`,
              creatorApplication: creatorApplication, // Pass på at vi inkluderer dette feltet korrekt
            }
          }) as Creator[]; // Type assertion her for å sikre at vi møter forventningene til Creator-typen
          setCreators(fetchedCreators);
        };
      
        fetchCreators();
      }, []);

      const handleCardClick = (creator: Creator) => {
        setSelectedCreator(creator);
        setIsDetailOpen(true);
      };
    
      const handleCloseDetail = () => {
        setIsDetailOpen(false);
        setSelectedCreator(null);
      };

    return (
      <>
        <div className="min-h-full">
          <div className="bg-[#465745] pb-32">
            <header className="py-10">
              <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
                <h1 className="text-3xl font-bold tracking-tight text-white">Creators</h1>
                {/* Legg til en knapp for å vise CreatorApplicationList */}
              <button
                onClick={() => setShowApplications(!showApplications)}
                className="mt-4 rounded-md bg-[#F1DDC2] py-2 px-4 text-sm font-bold text-[#884723] hover:bg-gray-100"
              >
                {'Vis søknader'}
              </button>
              </div>
            </header>
          </div>
  
          <main className="-mt-32 flex">
            {/* Betinget rendering av CreatorApplicationList basert på tilstanden */}
            {showApplications && <CreatorApplicationList applications={[] /* Pass riktig props basert på din datastruktur */} />}
            <div className="mx-auto max-w-7xl px-4 pb-12 sm:px-6 lg:px-8">
              <div className="rounded-lg bg-stone-50 px-5 py-6 shadow sm:px-6">
  
                {/* Grid Layout */}
                <div className="grid grid-cols-1 sm:grid-cols-3 lg:grid-cols-3">
                {creators.map(creator => (
                  <CreatorCard key={creator.id} creator={creator} onCardClick={handleCardClick}/>
                ))}
                </div>
  
              </div>
            </div>
          </main>
        </div>
      </>
    )
  }
  