import { Fragment, useState, useEffect } from 'react'
import { Dialog, Menu, Transition } from '@headlessui/react'
import { XMarkIcon } from '@heroicons/react/24/outline'
import { EllipsisVerticalIcon } from '@heroicons/react/20/solid'
import { onSnapshot, collection, query, where } from "firebase/firestore";
import { db } from "../../firebase";
import ListingCard from "../profile/listing/ListingCard";
import ListingDetail from "../profile/listing/ListingDetail";
import RecipeCard from "../profile/recipe/RecipeCard";
import RecipeDetail from "../profile/recipe/RecipeDetail";
import { useData } from '../../DataContext';

function classNames(...classes: any) {
  return classes.filter(Boolean).join(' ')
}

interface Listing {
  id: string;
  title: string;
  description: string;
  price: number;
  // Legg til andre felt basert på din Firestore-dokumentstruktur
}

interface Recipe {
  id: string;
  title: string;
  description: string;
  price: number;
  // Legg til andre felt basert på din Firestore-dokumentstruktur
}


export default function CreatorDetail({ creator, onClose }: any) {
  const [selectedListing, setSelectedListing] = useState<Listing | null>(null);
  const [isDetailOpen, setIsDetailOpen] = useState(false);
  const [listings, setListings] = useState<Listing[]>([]);
  const [selectedRecipe, setSelectedRecipe] = useState<Recipe | null>(null);
  const [recipes, setRecipes] = useState<Recipe[]>([]);
  const [open, setOpen] = useState(true)
  const { userData } = useData();

  const firebaseConsoleBaseUrl = "https://console.firebase.google.com/u/0/project/useless-411f7/firestore/data/~2Fusers~2F";
  


  const handleEditClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation(); // Stopper event-bobling
    window.open(`${firebaseConsoleBaseUrl}${creator.id}`, '_blank'); // Åpner Firebase URL i ny fane
  };
  

  const closeDialog = () => {
    setOpen(false);
    setTimeout(() => onClose(), 500);
  };

  useEffect(() => {
    const fetchListings = () => {
      const q = query(collection(db, "listings"), where("creatorId", "==", creator.id));
      const unsubscribe = onSnapshot(q, (querySnapshot) => {
        const listingsData: Listing[] = [];
        querySnapshot.forEach((doc) => {
          listingsData.push({
            id: doc.id,
            ...(doc.data() as Omit<Listing, 'id'>)
          });
        });
        setListings(listingsData);
      });

      return unsubscribe;
    };

    const unsubscribe = fetchListings();
    return () => unsubscribe();
  }, []);

  useEffect(() => {
    const fetchRecipes = () => {
      const q = query(collection(db, "recipes"), where("creatorId", "==", creator.id));
      const unsubscribe = onSnapshot(q, (querySnapshot: any) => {
        const recipesData: Recipe[] = [];
        querySnapshot.forEach((doc: any) => {
          recipesData.push({
            id: doc.id,
            ...(doc.data() as Omit<Listing, 'id'>)
          });
        });
        setRecipes(recipesData);
      });

      return unsubscribe;
    };

    const unsubscribe = fetchRecipes();
    return () => unsubscribe();
  }, []);

  const handleCardClick = (listing: Listing) => {
    setSelectedListing(listing);
    setIsDetailOpen(true);
  };

  const handleCloseDetail = () => {
    setIsDetailOpen(false);
    setSelectedListing(null);
  };

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={closeDialog}>
        <div className="fixed inset-0" />
        <Transition.Child
          as={Fragment}
          enter="ease-in-out duration-500"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in-out duration-500"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <Dialog.Panel className="pointer-events-auto w-screen max-w-2xl">
                  <div className="flex h-full flex-col overflow-y-scroll bg-stone-50 shadow-xl">
                    <div className="px-4 py-6 sm:px-6">
                      <div className="flex items-start justify-between">
                        <Dialog.Title className="text-base font-semibold leading-6 text-stone-900">{creator.name}'s shop</Dialog.Title>
                        <div className="ml-3 flex h-7 items-center">
                          <button
                            type="button"
                            className="relative rounded-md bg-stone-50 text-gray-400 hover:text-[#465745] focus:ring-2 focus:ring-[#8D4304]"
                            onClick={closeDialog}
                          >
                            <span className="absolute -inset-2.5" />
                            <span className="sr-only">Close panel</span>
                            <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                          </button>
                        </div>
                      </div>
                    </div>
                    {/* Main */}
                    <div className="divide-y divide-gray-200">
                      <div className="pb-6">
                        <div  className="h-24 bg-[#8D4304] sm:h-20 lg:h-28" />
                        <div className="-mt-12 flow-root px-4 sm:-mt-8 sm:flex sm:items-end sm:px-6 lg:-mt-16">
                          <div>
                            <div className="-m-1 flex">
                              <div className="inline-flex overflow-hidden rounded-lg border-4 border-white">
                                <img
                                  className="h-24 w-24 object-cover flex-shrink-0 sm:h-40 sm:w-40 lg:h-48 lg:w-48"
                                  src={creator.creatorApplication.imageURL}
                                  alt=""
                                />
                              </div>
                            </div>
                          </div>
                          <div className="mt-6 sm:ml-6 sm:flex-1">
                            <div>
                              <div className="flex items-center">
                                <h3 className="text-xl font-bold text-stone-900 sm:text-2xl">{creator.name}</h3>
                                            {userData.admin && (
                            <button
                              onClick={handleEditClick}
                              className="ml-2 inline-flex z-10 items-center rounded-full bg-blue-100 px-1.5 py-0.5 text-xs font-medium text-blue-700 hover:bg-blue-50"
                            >
                              Rediger
                            </button>
                          )}
                              </div>
                              <p className="text-sm text-gray-500">{creator.creatorApplication.location}</p>
                            </div>
                            <div className="mt-5 flex flex-wrap space-y-3 sm:space-x-3 sm:space-y-0">
                              <button
                                type="button"
                                onClick={()=>{window.location.href = "/creator/"+creator.id}}
                                className="inline-flex w-full flex-shrink-0 items-center justify-center rounded-md bg-[#8D4304] px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-[#465745] focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-[#8D4304] sm:flex-1"
                              >
                                Se shop
                              </button>
                              <button
                                type="button"
                                className="inline-flex w-full flex-1 items-center justify-center rounded-md bg-stone-50 px-3 py-2 text-sm font-semibold text-stone-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                              >
                                Send melding
                              </button>
                              <div className="ml-3 inline-flex sm:ml-0">
                                <Menu as="div" className="relative inline-block text-left">
                                  <Menu.Button className="relative inline-flex items-center rounded-md bg-stone-50 p-2 text-gray-400 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
                                    <span className="absolute -inset-1" />
                                    <span className="sr-only">Åpne flervalgsmeny</span>
                                    <EllipsisVerticalIcon className="h-5 w-5" aria-hidden="true" />
                                  </Menu.Button>
                                  <Transition
                                    as={Fragment}
                                    enter="transition ease-out duration-100"
                                    enterFrom="transform opacity-0 scale-95"
                                    enterTo="transform opacity-100 scale-100"
                                    leave="transition ease-in duration-75"
                                    leaveFrom="transform opacity-100 scale-100"
                                    leaveTo="transform opacity-0 scale-95"
                                  >
                                    <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-stone-50 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                      <div className="py-1">
                                        <Menu.Item>
                                          {({ active }) => (
                                            <a
                                              href="#"
                                              className={classNames(
                                                active ? 'bg-gray-100 text-stone-900' : 'text-gray-700',
                                                'block px-4 py-2 text-sm'
                                              )}
                                            >
                                              Se creator-shop
                                            </a>
                                          )}
                                        </Menu.Item>
                                        <Menu.Item>
                                          {({ active }) => (
                                            <a
                                              href="#"
                                              className={classNames(
                                                active ? 'bg-gray-100 text-stone-900' : 'text-gray-700',
                                                'block px-4 py-2 text-sm'
                                              )}
                                            >
                                              Kopier creator-shop link
                                            </a>
                                          )}
                                        </Menu.Item>
                                      </div>
                                    </Menu.Items>

                                  </Transition>
                                </Menu>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="px-4 py-5 sm:px-0 sm:py-0">
                        <dl className="space-y-8 sm:space-y-0 sm:divide-y sm:divide-gray-200">
                          <div className="sm:flex sm:px-6 sm:py-5">
                            <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0 lg:w-48">Bio</dt>
                            <dd className="mt-1 text-sm text-stone-900 sm:col-span-2 sm:ml-6 sm:mt-0">
                              <p>
                                {creator.creatorApplication.creatorBio}
                              </p>
                            </dd>
                          </div>
                          { /*
                          <div className="sm:flex sm:px-6 sm:py-5">
                            <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0 lg:w-48">
                              Sosiale medier
                            </dt>
                            <dd className="mt-1 text-sm text-stone-900 sm:col-span-2 sm:ml-6 sm:mt-0">
                              {creator.creatorApplication.socialMedia}
                            </dd>
                          </div>
                                              */ }
                        </dl>
                      </div>
                    </div>
                    <div className="m-10 grid grid-cols-1 gap-y-4 sm:grid-cols-2 sm:gap-x-4 sm:gap-y-10 lg:grid-cols-2 lg:gap-x-8">

                <>
                {listings.map((listing) => (
                  <ListingCard key={listing.id} listing={listing} onCardClick={handleCardClick} />
                  
                ))}
                {recipes.map((recipe) => (
                  <RecipeCard key={recipe.id} recipe={recipe} onCardClick={handleCardClick} />
                  
                ))}
                </>
               
              </div>
              {isDetailOpen && <ListingDetail listing={selectedListing} onClose={handleCloseDetail} />}
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}
