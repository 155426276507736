import { Fragment, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react';
import uselessLogo from "../images/uselessLogo.svg";
import { signInWithEmailAndPassword, signInWithPopup } from "firebase/auth";
import  { auth, provider, db } from "../firebase"
import { doc, setDoc, getDoc, getFirestore } from "firebase/firestore";
import { useData } from '../DataContext';

type LoginModalProps = {
    isOpen: boolean;
    setIsOpen: (open: boolean) => void; // Denne typen tilsvarer signaturen til en setState-funksjon for en boolean tilstand
  };

  export default function LoginModal({ isOpen, setIsOpen }: LoginModalProps) {
    const { userData, setUserData, userId, setUserId  } = useData();
    
  const closeModal = () => {
    setIsOpen(false);
  };

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loginError, setLoginError] = useState('');

  const createUserDocumentIfNotExists = async (user:any) => {
    const userDocRef = doc(db, "users", user.uid);
    const docSnap = await getDoc(userDocRef);
  
    if (!docSnap.exists()) {
      await setDoc(userDocRef, {
        email: user.email,
        name: user.displayName,
        profileImage: user.photoURL,
        uid: user.uid,
      });
    }
  };
  
  const handleLogin = async (event: any) => {
    event.preventDefault();
    try {
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      localStorage.setItem("uid", userCredential.user.uid);
      setUserId(userCredential.user.uid)
  
      setIsOpen(false); // Lukk modalen etter vellykket innlogging
      setLoginError('');
    } catch (error) {
      console.error("Auth Error:", error);
      setLoginError('Innlogging mislyktes. Vennligst sjekk dine legitimasjoner.');
    }
  };
  
  const handleGoogleLogin = async () => {
    try {
      const userCredential = await signInWithPopup(auth, provider);
      localStorage.setItem("uid", userCredential.user.uid);
      setUserId(userCredential.user.uid)
     
      await createUserDocumentIfNotExists(userCredential.user);
  
      setIsOpen(false); // Lukk modalen etter vellykket innlogging
    } catch (error) {
      console.error("Google Auth Error:", error);
      setLoginError('Google-innlogging mislyktes. Vennligst prøv igjen.');
    }
  };

  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={setIsOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-stone-50 px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6">
                
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
          <img
            className="mx-auto h-16 w-auto"
            src={uselessLogo}
            alt="Your Company"
          />
          <h2 className="mt-6 text-center text-2xl font-bold leading-9 tracking-tight text-stone-900">
            Logg inn på din konto
          </h2>
        </div>

        <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-[480px] p-2">
            <form className="space-y-6" onSubmit={handleLogin}>
              <div>
                <label htmlFor="email" className="block text-sm font-medium leading-6 text-stone-900">
                  E-post
                </label>
                <div className="mt-2">
                  <input
                    id="email"
                    name="email"
                    type="email"
                    autoComplete="email"
                    onChange={(e) => setEmail(e.target.value)}
                    required
                    className="block p-2 w-full rounded-md border-0 py-1.5 text-stone-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#f36699] sm:text-sm sm:leading-6"
                  />
                </div>
              </div>

              <div>
                <label htmlFor="password" className="block text-sm font-medium leading-6 text-stone-900">
                  Passord
                </label>
                <div className="mt-2">
                  <input
                    id="password"
                    name="password"
                    type="password"
                    autoComplete="current-password"
                    onChange={(e) => setPassword(e.target.value)}
                    required
                    className="block p-2 w-full rounded-md border-0 py-1.5 text-stone-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#f36699] sm:text-sm sm:leading-6"
                  />
                </div>
              </div>

              <div className="flex items-center justify-between">
                <div className="flex items-center">
                  <input
                    id="remember-me"
                    name="remember-me"
                    type="checkbox"
                    className="h-4 w-4 rounded border-gray-300 text-[#f36699] focus:ring-[#f36699]"
                  />
                  <label htmlFor="remember-me" className="ml-3 block text-sm leading-6 text-stone-900">
                    Husk meg
                  </label>
                </div>

                <div className="text-sm leading-6">
                  <a href="#" className="font-semibold text-[#f36699] hover:text-[#465745]">
                    Glemt passord?
                  </a>
                </div>
              </div>

              <div>
                <button
                  type="submit"
                  className="flex w-full justify-center rounded-md bg-[#f36699] px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-[#465745] focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-[#465745]"
                >
                  Logg inn
                </button>
                {loginError && <div className="text-red-500">{loginError}</div>}
              </div>
            </form>

            <div>
              <div className="relative mt-10">
                <div className="absolute inset-0 flex items-center" aria-hidden="true">
                  <div className="w-full border-t border-gray-200" />
                </div>
                <div className="relative flex justify-center text-sm font-medium leading-6">
                  <span className="bg-stone-50 px-6 text-stone-900">Eller fortsett med</span>
                </div>
              </div>

              <div className="mt-6">
                <a
                  onClick={handleGoogleLogin}
                  className="flex cursor-pointer w-full items-center justify-center gap-3 rounded-md bg-stone-50 px-3 py-2 text-sm font-semibold text-stone-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus-visible:ring-transparent"
                >
                  <svg className="h-5 w-5" aria-hidden="true" viewBox="0 0 24 24">
                    <path
                      d="M12.0003 4.75C13.7703 4.75 15.3553 5.36002 16.6053 6.54998L20.0303 3.125C17.9502 1.19 15.2353 0 12.0003 0C7.31028 0 3.25527 2.69 1.28027 6.60998L5.27028 9.70498C6.21525 6.86002 8.87028 4.75 12.0003 4.75Z"
                      fill="#EA4335"
                    />
                    <path
                      d="M23.49 12.275C23.49 11.49 23.415 10.73 23.3 10H12V14.51H18.47C18.18 15.99 17.34 17.25 16.08 18.1L19.945 21.1C22.2 19.01 23.49 15.92 23.49 12.275Z"
                      fill="#4285F4"
                    />
                    <path
                      d="M5.26498 14.2949C5.02498 13.5699 4.88501 12.7999 4.88501 11.9999C4.88501 11.1999 5.01998 10.4299 5.26498 9.7049L1.275 6.60986C0.46 8.22986 0 10.0599 0 11.9999C0 13.9399 0.46 15.7699 1.28 17.3899L5.26498 14.2949Z"
                      fill="#FBBC05"
                    />
                    <path
                      d="M12.0004 24.0001C15.2404 24.0001 17.9654 22.935 19.9454 21.095L16.0804 18.095C15.0054 18.82 13.6204 19.245 12.0004 19.245C8.8704 19.245 6.21537 17.135 5.2654 14.29L1.27539 17.385C3.25539 21.31 7.3104 24.0001 12.0004 24.0001Z"
                      fill="#34A853"
                    />
                  </svg>
                  <span className="text-sm font-semibold leading-6">Google</span>

                </a>
              </div>
           
          </div>

        
      </div>
      </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}