import React from 'react';
import { useData } from '../../DataContext';



interface CreatorApplication {
  age: string;
  creatorBio: string;
  imageURL: string;
  location: string;
  otherComments: string;
  reason: string;
  recipe: boolean;
  socialMedia: string;
  sow: boolean;
}

interface Creator {
  id: string;
  name: string;
  specialty: string;
  description: string;
  collections: string;
  imageSrc: string;
  imageAlt: string;
  creatorApplication: CreatorApplication; // Inkluderer CreatorApplication som en del av Creator-typen
}


interface CreatorCardProps {
  creator: Creator;
  onCardClick: (creator: Creator) => void;
}

export default function CreatorCard({ creator, onCardClick }: CreatorCardProps) {
  const { userData } = useData();

  const handleClick = () => {
    onCardClick(creator);
  };


    return (
      <div onClick={handleClick}>
        <div className="mx-auto max-w-2xl p-8 sm:px-3 sm:py-6 lg:max-w-7xl lg:px-4">
          <h2 className="sr-only">Creators</h2>
              <div
                key={creator.id}
                className="cursor-pointer sm:m-0 group relative flex flex-col overflow-hidden rounded-2xl border border-gray-200 bg-stone-50 hover:shadow hover:shadow-pink-300"
              >
                <div className="sm:h-56 w-full bg-gray-200 overflow-hidden">
                  <img
                    src={creator.creatorApplication.imageURL}
                    alt={creator.imageAlt}
                    className="object-cover w-full h-full"
                  />
                </div>
                <div className="flex flex-1 flex-col space-y-2 p-4">
                  <div className="flex justify-between">
                    <h3 className="text-sm font-medium text-stone-900">
                        <span aria-hidden="true" className="absolute inset-0" />
                        {creator.name}
                    </h3>
                    
                  </div>
                  <p className="text-sm text-gray-500 line-clamp-3">{creator.creatorApplication.creatorBio}</p>
                  <div className="flex flex-1 flex-col justify-end">
                  <p className="text-sm italic text-gray-500">{creator.creatorApplication.location}</p>
                  <p className="text-base font-medium text-stone-900">
                   
                  </p>
                  <div className="flex">
                  {creator.creatorApplication.sow && (
                      <span className="mt-2 inline-flex items-center rounded-full bg-purple-100 px-1.5 py-0.5 text-xs font-medium text-purple-700">
                        Syr klær
                      </span>
                    )}
                    {creator.creatorApplication.recipe && (
                      <span className="mt-2 inline-flex items-center ml-2 rounded-full bg-pink-100 px-1.5 py-0.5 text-xs font-medium text-pink-700">
                        Lager mønstre
                      </span>
                    )}
                    </div>
                </div>

                </div>
              </div>
        </div>
      </div>
    );
}