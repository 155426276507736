import React, { useEffect, useState } from 'react';
import { db } from '../../firebase'; // Pass på at denne stien stemmer overens med hvor din Firebase-config er
import { collection, doc, getDoc } from "firebase/firestore";
import { useData } from '../../DataContext';
import firebase from 'firebase/compat/app';

interface ProductInCart {
  about: string;
  creatorId: string;
  headline: string;
  id: string;
  mainCategory: string;
  photoURLs: string[];
  price: string;
  stock: number;
  step: number;
}

interface ShippingInfo {
  address: {
    city: string;
    country: string;
    line1: string;
    line2: string | null;
    postal_code: string;
    state: string;
  };
  name: string;
}

interface Order {
  orderId: string;
  amountTotal: number;
  cart: ProductInCart[];
  paymentIntent: string;
  shippingInfo: ShippingInfo;
  timestamp: firebase.firestore.Timestamp;
  userEmail: string;
  userId: string;
}

function classNames(...classes: any) {
  return classes.filter(Boolean).join(' ')
}

export default function PurchaseHistory() {
  const [orders, setOrders] = useState<Order[]>([]);
  const { userData } = useData();

  useEffect(() => {
    const fetchOrders = async () => {
      const ordersData = await Promise.all(userData.orderHistory.map(async (orderRef: any) => {
        const orderSnap = await getDoc(doc(db, "sales", orderRef.orderId));
        if (orderSnap.exists()) {
          return { orderId: orderRef.orderId, ...orderSnap.data() } as Order;
        }
        return null;
      }));
      setOrders(ordersData.filter((order): order is Order => order !== null));
      console.log(ordersData.filter((order): order is Order => order !== null))
    };
  
    if (userData.orderHistory) {
      fetchOrders();
    }
  }, [userData.orderHistory]);

    return (
      <>

        <main className="mx-auto max-w-2xl pb-24 pt-8 sm:px-3 sm:pt-16 lg:max-w-7xl lg:px-4">
        <div className="space-y-2 px-4 sm:flex sm:items-baseline sm:justify-between sm:space-y-0 sm:px-0">
          <div className="flex sm:items-baseline sm:space-x-4">
            <h1 className="text-2xl font-bold tracking-tight text-stone-900 sm:text-3xl">Kjøpshistorikk</h1>
          </div>
        </div>

        {/* Products */}
        <section aria-labelledby="products-heading" className="mt-6">
          <h2 id="products-heading" className="sr-only">
            Bestilte produkter
          </h2>

          <div className="space-y-8">
            
          {orders.map((order) => (
        <div key={order.orderId} className="border-b border-t border-gray-200 bg-stone-50 shadow-sm sm:rounded-lg sm:border">
          <div className="px-4 py-6 sm:px-6 lg:grid lg:grid-cols-12 lg:gap-x-8 lg:p-8">
            <div className="lg:col-span-7">
              <h3 className="text-base font-medium text-stone-900">Ordre ID - {order.orderId}</h3>
              <p className="mt-1 text-sm text-gray-500">Dato: {order.timestamp.toDate().toLocaleDateString()}</p>
            </div>
            <div className="mt-6 lg:col-span-5 lg:mt-0">
              <dl className="text-sm">
                <dt className="font-medium text-stone-900">Totalbeløp</dt>
                <dd className="mt-3 text-stone-900">{(order.amountTotal / 100).toFixed(2)} NOK</dd>
                <dt className="font-medium text-stone-900 mt-4">Leveringsadresse</dt>
                <dd className="mt-3 text-gray-500">
                  {order.shippingInfo.address?.line1}, {order.shippingInfo.address?.city}, {order.shippingInfo.address?.postal_code}, {order.shippingInfo.address?.country}
                </dd>
              </dl>
            </div>
          </div>
          {order.cart.map((item, index) => (
            <div key={index} className="border-t border-gray-200 px-4 py-6 sm:px-6 lg:p-8">
              <div className="flex">
                <img className="h-20 w-20 object-cover rounded" src={item.photoURLs[0]} alt={item.headline} />
                <div className="ml-4">
                  <p className="text-sm font-medium text-stone-900">{item.headline}</p>
                  <p className="text-sm text-gray-500">{item.about}</p>
                  <p className="text-sm text-stone-900">Pris: {item.price} NOK</p>
                </div>
              </div>
              <div className="mt-4">
                <div className="overflow-hidden rounded-full bg-gray-200">
                  <div className="h-2 rounded-full bg-[#F36699]" style={{ width: `calc((${item.step} * 2 + 1) / 8 * 100%)` }}></div>
                </div>
                <div className="mt-2 grid grid-cols-4 gap-4 text-sm font-medium text-gray-600">
                  <div className={classNames(item.step >= 1 ? 'text-[#F36699]' : '')}>Ordre lagt inn</div>
                  <div className={classNames(item.step >= 2 ? 'text-[#F36699]' : '', 'text-center')}>Behandler</div>
                  <div className={classNames(item.step >= 3 ? 'text-[#F36699]' : '', 'text-center')}>Sendt</div>
                  <div className={classNames(item.step === 4 ? 'text-[#F36699]' : '', 'text-right')}>Levert</div>
                </div>
              </div>
            </div>
          ))}
        </div>
      ))}
          </div>
        </section>
      </main>
      </>
    )
  }
  