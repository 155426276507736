import React, { useState, Fragment, useRef } from 'react';
import { useData } from '../../DataContext';
import { Dialog, Transition } from '@headlessui/react';
import { CheckCircleIcon, ExclamationTriangleIcon, PencilIcon } from '@heroicons/react/20/solid';
import { doc, updateDoc } from "firebase/firestore";
import { db, storage } from "../../firebase";
import { ref as storageRef, uploadBytes, getDownloadURL } from "firebase/storage";
import CreatorApplication from './CreatorApplication';

export default function Personal() {
  const { userData, userId } = useData();
  const [open, setOpen] = useState(false);
  const [fieldToUpdate, setFieldToUpdate] = useState('');
  const [newValue, setNewValue] = useState('');
  const [showCreatorModal, setShowCreatorModal] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [showUploadImage, setShowUploadImage] = useState(false);
  const [showUploadCreatorImage , setShowUploadCreatorImage] = useState(false);

  const cancelButtonRef = useRef(null);

  const handleImageUpload = async (field: 'profileImage' | 'creatorApplication.imageURL', e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files ? e.target.files[0] : null;
    if (!file) return;
    setUploading(true);

    // Determine the correct path based on the field to be updated
    const path = field === 'profileImage' ? `profileImages/${userId}` : `creatorImages/${userId}`;
    const fileRef = storageRef(storage, `${path}/${file.name}`);
    try {
        const snapshot = await uploadBytes(fileRef, file);
        const photoURL = await getDownloadURL(snapshot.ref);
        await updateUserData(field, photoURL);
    } catch (error) {
        console.error("Error uploading image: ", error);
    } finally {
        setUploading(false);
        setShowUploadImage(false)
        setShowUploadCreatorImage(false)
    }
};


  const handleShowCreatorModal = () => {
    setShowCreatorModal(true);
  };

  const handleCloseCreatorModal = () => {
    setShowCreatorModal(false);
  };

  const updateUserData = async (field: any, newValue: any) => {
    if (!userId) return;

    try {
      await updateDoc(doc(db, "users", userId), { [field]: newValue });
      // Optionally, refresh user data here
    } catch (error) {
      console.error("Error updating document: ", error);
    }
  };

  const handleOpenModal = (field: any) => {
    setFieldToUpdate(field);
    setOpen(true);
  };

  const handleUpdateClick = async () => {
    if (fieldToUpdate !== 'profileImage' && fieldToUpdate !== 'creatorApplication.imageURL') {
      await updateUserData(fieldToUpdate, newValue);
    }
    setNewValue("");
    setOpen(false);
  };

  const handleChange = (e: any) => {
    setNewValue(e.target.value);
  };

  const onTogglePublish = async () => {
    const creatorDocRef = doc(db, "users", userId);
  
    try {
      await updateDoc(creatorDocRef, {
        creatorIsActive: !userData.creatorIsActive
      });
      console.log("Creator synlighetsstatus oppdatert");
      // Du kan også oppdatere tilstanden her for å reflektere endringen umiddelbart i UI
    } catch (error) {
      console.error("Kunne ikke oppdatere creator synlighetsstatus", error);
    }
  };

  return(

<main className="px-4 py-16 sm:px-6 lg:flex-auto lg:px-0 lg:py-20">
<Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-stone-50 px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                <div className="sm:flex sm:items-start">
                  <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                    <ExclamationTriangleIcon className="h-6 w-6 text-[#F36699]" aria-hidden="true" />
                  </div>
                  <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                    <Dialog.Title as="h3" className="text-2xl mt-2 font-semibold leading-6 text-stone-900">
                      Endre {fieldToUpdate=="name"?"navn":fieldToUpdate}
                    </Dialog.Title>
                  </div>
                  
                </div>
                
                <div className="mt-5 sm:mt-4 sm:flex">
                <input
              type="text"
              className="p-2 block w-full rounded-md border-0 py-1.5 pr-10 text-red-900 ring-1 ring-inset ring-[#F36699] placeholder:text-[#F36699] focus:ring-2 focus:ring-inset focus:ring-[#F36699] sm:text-sm sm:leading-6"
              value={newValue}
              placeholder={userData[fieldToUpdate]}
              onChange={handleChange}
            />
            
                  <button
                    type="button"
                    className="inline-flex mr-2 w-full justify-center rounded-md bg-[#F36699] px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-gray-500 sm:ml-3 sm:w-auto"
                    onClick={handleUpdateClick}
                  >
                    Oppdater
                  </button>
                  <button
                    type="button"
                    className="mt-3 inline-flex w-full justify-center rounded-md bg-stone-50 px-3 py-2 text-sm font-semibold text-stone-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                    onClick={() => setOpen(false)}
                    ref={cancelButtonRef}
                  >
                    Avbryt
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>

          <div className="mx-auto max-w-2xl space-y-16 sm:space-y-20 lg:mx-0 lg:max-w-none">
            <div>
            <div className="flex">
            <img src={userData.profileImage} alt="Creator Bilde" className="h-20 w-20 rounded-full object-cover m-4 ml-0" />
                           
               <div>
               <button
                              type="button"
                              className="relative -mr-2 flex h-8 w-8 items-center justify-center rounded-full bg-stone-50 text-gray-300 hover:bg-gray-100 hover:text-[#465745] focus:outline-none focus:ring-2 focus:ring-[#F1DDC2]"
                              onClick={()=> {setShowUploadImage(true)}}
                            >
                              <PencilIcon className="h-6 w-6" aria-hidden="true" />
                            </button>
              <h2 className="text-base font-semibold leading-7 text-stone-900">Personlig</h2>
              <p className="mt-1 text-sm leading-6 text-gray-500">
                Denne informasjonen blir offentlig tilgjengelig, så vær forsiktig med hva du deler.
              </p>
              
              </div>
              </div>
              {showUploadImage?
                          <input
                            type="file"
                            accept="image/*"
                            onChange={(e) => handleImageUpload('profileImage', e)} 
                            disabled={uploading}
                          />:""}

              <dl className="mt-6 space-y-6 divide-y divide-gray-100 border-t border-gray-200 text-sm leading-6">
                <div className="pt-6 sm:flex">
                  <dt className="font-medium text-stone-900 sm:w-64 sm:flex-none sm:pr-6">Fullt navn</dt>
                  <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                    <div className="text-stone-900">{userData.name}</div>
                    <button type="button"  onClick={() => handleOpenModal('name')} className="font-semibold text-[#8D4304] hover:text-[#465745]">
                      Oppdater
                    </button>
                  </dd>
                </div>
                <div className="pt-6 sm:flex">
                  <dt className="font-medium text-stone-900 sm:w-64 sm:flex-none sm:pr-6">E-post</dt>
                  <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                    <div className="text-stone-900">{userData.email}</div>
                    <button type="button"  onClick={() => handleOpenModal('email')} className="font-semibold text-[#8D4304] hover:text-[#465745]">
                      Oppdater
                    </button>
                  </dd>
                </div>
                <div className="pt-6 sm:flex">
                  <dt className="font-medium text-stone-900 sm:w-64 sm:flex-none sm:pr-6">Bio</dt>
                  <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                    <div className="text-stone-900">{userData.bio}</div>
                    <button type="button" onClick={() => handleOpenModal('bio')} className="font-semibold text-[#8D4304] hover:text-[#465745]">
                      Oppdater
                    </button>
                  </dd>
                </div>
                <div className="pt-6 sm:flex">
                  <dt className="font-medium text-stone-900 sm:w-64 sm:flex-none sm:pr-6">Useless-Status</dt>
                  <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                    <div className="text-stone-900">{userData.creator?"Creator":"Bruker"}</div>
                    {userData.creator || userData.creatorApplication ? "" : ( 
                    <button
                      type="button"
                      className="inline-flex items-center gap-x-2 rounded-md bg-[#8D4304] px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-[#465745] focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600"
                      onClick={handleShowCreatorModal}
                    >
                  <CheckCircleIcon className="-ml-0.5 h-5 w-5" aria-hidden="true" />
                  Søk om å bli creator
                </button>
                
                )}
                  </dd>
                </div>
              </dl>
            </div>
            {userData.creator?
                        <div>
                          <div className="flex">
                           <img src={userData.creatorApplication.imageURL} alt="Creator Bilde" className="h-20 w-20 rounded-full object-cover m-4 ml-0" />
                           
                          <div>
                          <button
                              type="button"
                              className="relative -mr-2 flex h-8 w-8 items-center justify-center rounded-full bg-stone-50 text-gray-300 hover:bg-gray-100 hover:text-[#465745] focus:outline-none focus:ring-2 focus:ring-[#F1DDC2]"
                              onClick={()=> {setShowUploadCreatorImage(true)}}
                           >
                              <PencilIcon className="h-6 w-6" aria-hidden="true" />
                            </button>
                            <div className='flex'>
                        <h2 className="mt-2 text-base font-semibold leading-7 text-[#8D4304]">Creator</h2>
                      
                        <button
                          onClick={onTogglePublish}
                          className="ml-8 text-sm rounded-lg bg-pink-100 hover:bg-pink-50 px-2 my-1">
                          {userData.creatorIsActive ? "Skjul profil" : "Vis profil"}
                        </button>
                        </div>
                        <p className="mt-1 text-sm leading-6 text-gray-500">
                          Du er en creator, og har ekstra synlighet på Useless.
                        </p>
                        </div>
                        </div>
                        {showUploadCreatorImage?
                          <input
                            type="file"
                            accept="image/*"
                            onChange={(e) => handleImageUpload('creatorApplication.imageURL', e)} 
                            disabled={uploading}
                          />:""}
          
                        <dl className="mt-6 space-y-6 divide-y divide-gray-100 border-t border-gray-200 text-sm leading-6">
                          <div className="pt-6 sm:flex">
                            <dt className="font-medium text-stone-900 sm:w-64 sm:flex-none sm:pr-6">Alder</dt>
                            <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                              <div className="text-stone-900">{userData.creatorApplication.age}</div>
                              <button type="button"  onClick={() => handleOpenModal('creatorApplication.age')} className="font-semibold text-[#8D4304] hover:text-[#465745]">
                                Oppdater
                              </button>
                            </dd>
                          </div>
                          <div className="pt-6 sm:flex">
                            <dt className="font-medium text-stone-900 sm:w-64 sm:flex-none sm:pr-6">Creator-bio</dt>
                            <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                              <div className="text-stone-900">{userData.creatorApplication.creatorBio}</div>
                              <button type="button"  onClick={() => handleOpenModal('creatorApplication.creatorBio')} className="font-semibold text-[#8D4304] hover:text-[#465745]">
                                Oppdater
                              </button>
                            </dd>
                          </div>
                          <div className="pt-6 sm:flex">
                            <dt className="font-medium text-stone-900 sm:w-64 sm:flex-none sm:pr-6">Sted (fylke)</dt>
                            <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                              <div className="text-stone-900">{userData.creatorApplication.location}</div>
                              <button type="button" onClick={() => handleOpenModal('creatorApplication.location')} className="font-semibold text-[#8D4304] hover:text-[#465745]">
                                Oppdater
                              </button>
                            </dd>
                          </div>
                          <div className="pt-6 sm:flex">
                            <dt className="font-medium text-stone-900 sm:w-64 sm:flex-none sm:pr-6">Shop temafarge</dt>
                            <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                              <div className="text-stone-900">{userData.creatorApplication.themeColor}</div>
                              <button type="button" onClick={() => handleOpenModal('creatorApplication.themeColor')} className="font-semibold text-[#8D4304] hover:text-[#465745]">
                                Oppdater
                              </button>
                            </dd>
                          </div>
                          <div className="pt-6 sm:flex">
                            <dt className="font-medium text-stone-900 sm:w-64 sm:flex-none sm:pr-6">Kontonummer</dt>
                            <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                              <div className="text-stone-900">{userData.creatorApplication.accountNumber}</div>
                              <button type="button" onClick={() => handleOpenModal('creatorApplication.accountNumber')} className="font-semibold text-[#8D4304] hover:text-[#465745]">
                                Oppdater
                              </button>
                            </dd>
                          </div>


                        </dl>
                      </div>:""}

          </div>
          {showCreatorModal && <CreatorApplication onClose={handleCloseCreatorModal} />}
        </main>

)}