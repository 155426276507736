import React, { Fragment, useEffect, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react'
import { ShareIcon, XMarkIcon, ShoppingBagIcon, FingerPrintIcon, ArrowRightIcon } from '@heroicons/react/24/outline'
import { PencilIcon, PlusIcon } from '@heroicons/react/20/solid'
import { useData } from '../../../DataContext';
import LoginModal from '../../LoginModal';
import DeleteListing from './DeleteListing';
import EditListing from './EditListing';


import { doc, updateDoc, arrayUnion, deleteDoc } from "firebase/firestore";
import { db } from '../../../firebase';


export default function ListingDetail({ listing, onClose }: any) {
  const [open, setOpen] = useState(true);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false); 
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const currentUserID = localStorage.getItem('uid');
  const { userData } = useData();
  const firebaseConsoleBaseUrl = "https://console.firebase.google.com/u/0/project/useless-411f7/firestore/data/~2Flistings~2F";
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedSize, setSelectedSize] = useState('');
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);

  const handleDelete = async () => {
    // Implementer slett-logikken her, f.eks.:
    await deleteDoc(doc(db, "listings", listing.id));
    setIsDeleteModalOpen(false); // Lukk modalen etter vellykket sletting
    // Legg til eventuell opprydding eller navigering her
  };

  const closeDialog = () => {
    setOpen(false);
    setTimeout(() => onClose(), 500);
  };

  const handleImageClick = () => {
    setCurrentImageIndex(prevIndex => 
      (prevIndex + 1) % listing.photoURLs.length
    );
  };

  const handleCart = async () => {
    const currentUserID = localStorage.getItem('uid'); // Hent brukerens UID fra localStorage
    if (!currentUserID) {
      alert('Du må være logget inn for å legge til i handlekurven.');
      return; // Hvis det ikke finnes en UID, avslutt funksjonen
    }
  
    if (!selectedSize) {
      alert('Vennligst velg en størrelse før du fortsetter.');
      return;
    }
    const updatedListing = { ...listing, selectedSize: selectedSize };
  
    const userDocRef = doc(db, "users", currentUserID); // Referanse til brukerens dokument i Firestore
  
    try {
      // Oppdaterer hele 'cart'-feltet med kun det nye elementet
      // Dette erstatter alle tidligere elementer i handlekurven med det nye
      await updateDoc(userDocRef, {
        cart: [updatedListing] // Bruker et array med kun det oppdaterte elementet
      });
      window.location.href = "/checkout";
    } catch (error) {
      console.error("Kunne ikke legge til listing i handlekurven", error);
      alert("Det oppstod en feil ved å legge til listingen i handlekurven.");
    }
  };
  


  const handleEditClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation(); // Stopper event-bobling
    window.open(`${firebaseConsoleBaseUrl}${listing.id}`, '_blank'); // Åpner Firebase URL i ny fane
  };

  const isCreator = currentUserID === listing.creatorId;

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={closeDialog}>
        <Transition.Child
          as={Fragment}
          enter="ease-in-out duration-500"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in-out duration-500"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>
        <LoginModal isOpen={isModalOpen} setIsOpen={setIsModalOpen} />
        <DeleteListing
        isOpen={isDeleteModalOpen}
        onClose={() => setIsDeleteModalOpen(false)}
        onDelete={handleDelete}
      />
      {isEditModalOpen && (
      <EditListing
        listing={listing}
        onClose={() => setIsEditModalOpen(false)}
      />
    )}

        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <Dialog.Panel className="pointer-events-auto relative w-96">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-in-out duration-500"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in-out duration-500"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <div className="absolute left-0 top-0 -ml-8 flex pr-2 pt-4 sm:-ml-10 sm:pr-4">
                      <button
                        type="button"
                        className="relative rounded-md text-gray-300 hover:text-white focus:outline-none focus:ring-2 focus:ring-white"
                        onClick={closeDialog}
                      >
                        <span className="absolute -inset-2.5" />
                        <span className="sr-only">Lukk panel</span>
                        <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                      </button>
                    </div>
                  </Transition.Child>
                  <div className="h-full overflow-y-auto bg-stone-50 p-8">
                    <div className="space-y-6 pb-16">
                      <div>
                      <div className="aspect-h-7 aspect-w-10 block w-full overflow-hidden rounded-lg relative">
  <img
    src={listing.photoURLs[currentImageIndex]}
    alt={listing.headline}
    onClick={handleImageClick}
    className="object-cover w-full h-full"
  />
  <div
    className="absolute inset-0 flex items-start justify-end"
    onClick={handleImageClick}
  >
   <FingerPrintIcon className="h-8 w-8 m-4 text-white pulse-animation lg:hidden" aria-hidden="true"></FingerPrintIcon>
  <ArrowRightIcon className="hidden lg:block h-8 w-8 m-4 text-white pulse-animation"></ArrowRightIcon>
  </div>
</div>
                        <div className="mt-4 flex items-start justify-between">
                          <div>
                            <h2 className="text-base font-semibold leading-6 text-stone-900">
                              <span className="sr-only">Detaljer for </span>{listing.headline}
                              {userData.admin && (
                              <button
                                onClick={handleEditClick}
                                className="ml-2 inline-flex z-10 items-center rounded-full bg-blue-100 px-1.5 py-0.5 text-xs font-medium text-blue-700 hover:bg-blue-50"
                              >
                                Rediger
                              </button>
                            )}
                            </h2>
                            <p className="text-sm font-medium text-gray-500">{listing.price} kr</p>
                          </div>
                          <button
                            type="button"
                            className="relative ml-4 flex h-8 w-8 items-center justify-center rounded-full bg-stone-50 text-gray-400 hover:bg-gray-100 hover:text-[#465745] focus:outline-none focus:ring-2 focus:ring-[#F1DDC2]"
                          >
                            <span className="absolute -inset-1.5" />
                            <ShareIcon className="h-6 w-6" aria-hidden="true" />
                            <span className="sr-only">Favorite</span>
                          </button>
                        </div>
                      </div>
                      <div>
                        <h3 className="font-medium text-stone-900">Beskrivelse</h3>
                        <div className="mt-2 flex items-center justify-between">
                          <p className="text-sm italic text-gray-500">{listing.about}</p>
                          {isCreator && (
                            <button
                              type="button"
                              className="relative -mr-2 flex h-8 w-8 items-center justify-center rounded-full bg-stone-50 text-gray-400 hover:bg-gray-100 hover:text-[#465745] focus:outline-none focus:ring-2 focus:ring-[#F1DDC2]"
                              onClick={() => setIsEditModalOpen(true)}
                            >
                              <PencilIcon className="h-6 w-6" aria-hidden="true" />
                            </button>
                          )}
                        </div>
                      </div>
                      <div>
                        <h3 className="font-medium text-stone-900">Mer informasjon</h3>
                        <dl className="mt-2 divide-y divide-gray-200 border-b border-t border-gray-200">
                          <div className="flex justify-between py-3 text-sm font-medium">
                            <dt className="text-gray-500">Laget av</dt>
                            <dd className="text-stone-900">{listing.creatorName ? listing.creatorName:""}</dd>
                          </div>
                          <div className="flex justify-between py-3 text-sm font-medium">
                            <dt className="text-gray-500">Kategori</dt>
                            <dd className="text-stone-900">{listing.mainCategory}</dd>
                          </div>
                          <div className="flex justify-between py-3 text-sm font-medium">
                            <dt className="text-gray-500">Antall på lager</dt>
                            <dd className="text-stone-900">{listing.stock}</dd>
                          </div>
                        </dl>
                      </div>

                                      <div>
                  <h3 className="font-medium text-gray-900">Størrelser</h3>
                  <div className="mt-2">
                    {listing.sizes?.map((size: any, index: any) => (
                      <button
                        key={index}
                        className={`mr-2 p-1 px-4  ${selectedSize === size ? 'bg-stone-500 text-white' : 'bg-stone-200'} rounded`}
                        onClick={() => setSelectedSize(size)}
                      >
                        {size}
                      </button>
                    ))}
                  </div>
                </div>
                
                      <div className="flex">
                        <button
                          type="button"
                          className="flex-1 rounded-md bg-[#F1DDC2] px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-gray-300 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-[#F1DDC2]"
                          onClick={closeDialog}
                        >
                          Tilbake
                        </button>
                        {isCreator ? (
                          <button
                            type="button"
                            className="ml-3 flex-1 rounded-md bg-red-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-700"
                            onClick={() => setIsDeleteModalOpen(true)}
                          >
                            Slett
                          </button>
                        ) : (
                          <button
                            type="button"
                            className="ml-3 flex-1 rounded-md bg-[#455644] px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-700"
                            onClick={() => {localStorage.getItem('uid')?handleCart():setIsModalOpen(true)}}
                          >
                             Kjøp
                          </button>
                        )}
                      </div>
                      <button
                            type="button"
                            className=" w-full rounded-md bg-[#884723] px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-gray-700"
                            onClick={()=>{window.location.href = "/creator/"+listing.creatorId}}
                          >
                             Se mer fra {listing.creatorName ? listing.creatorName:""}
                          </button>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}
