import Overview from '../components/admin/Overview'; 
import Creators from '../components/admin/Creators'; 
import ListingsAndRecipes from '../components/admin/ListingsAndRecipes'; 
import Courses from '../components/admin/Courses'; 
import Reports from '../components/admin/Reports'; 
import { useEffect } from 'react';
import { useData } from '../DataContext';

import { Fragment, useState } from 'react'
import { Dialog, Menu, Transition } from '@headlessui/react'
import {
  CalendarIcon,
  ChartPieIcon,
  Cog6ToothIcon,
  FolderIcon,
  HomeIcon,
  UsersIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline'

import logo from '../images/UseLess Logo Symbol - Beige & Brown.png'


const navigation = [
 // { name: 'Oversikt', href: '#', icon: HomeIcon, current: true },
  { name: 'Creators', href: '#', icon: UsersIcon, current: true },
 // { name: 'Listings og oppskrifter', href: '#', icon: FolderIcon, current: false },
  { name: 'Kurs', href: '#', icon: CalendarIcon, current: false },
  { name: 'Salgsoversikt', href: '#', icon: ChartPieIcon, current: false },
]

function classNames(...classes: any) {
  return classes.filter(Boolean).join(' ')
}

export default function AdminPage() {
  const [sidebarOpen, setSidebarOpen] = useState(false)
  const [currentNavItem, setCurrentNavItem] = useState('Creators'); 
  const { userData } = useData();

  useEffect(() => {
    console.log(localStorage.getItem("admin"))
    if (localStorage.getItem("admin") == "false") {
      window.location.href = '/';
    }
  }, [userData]);

  const handleNavigationChange = (name: string) => {
    setCurrentNavItem(name);
    // Oppdater 'current'-feltet for hvert element i 'navigation'
    navigation.forEach((item) => {
      item.current = item.name === name;
    });
  };

  const renderCurrentComponent = () => {
    switch(currentNavItem) {
      case 'Oversikt':
        return <Overview />;
      case 'Creators':
        return <Creators />;
      case 'Listings og oppskrifter':
        return <ListingsAndRecipes />;
      case 'Kurs':
        return <Courses />;
      case 'Rapporter':
        return <Reports />;
      default:
        return null;
    }
  }

  return (
    <>
      {/*
        This example requires updating your template:

        ```
        <html class="h-full bg-stone-50">
        <body class="h-full">
        ```
      */}
      <div>
        <Transition.Root show={sidebarOpen} as={Fragment}>
          <Dialog as="div" className="relative z-50 lg:hidden" onClose={setSidebarOpen}>
            <Transition.Child
              as={Fragment}
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-[#F1DDC2]" />
            </Transition.Child>

            <div className="fixed inset-0 flex">
              <Transition.Child
                as={Fragment}
                enter="transition ease-in-out duration-300 transform"
                enterFrom="-translate-x-full"
                enterTo="translate-x-0"
                leave="transition ease-in-out duration-300 transform"
                leaveFrom="translate-x-0"
                leaveTo="-translate-x-full"
              >
                <Dialog.Panel className="relative mr-16 flex w-full max-w-xs flex-1">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-in-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in-out duration-300"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <div className="absolute left-full top-0 flex w-16 justify-center pt-5">
                      <button type="button" className="-m-2.5 p-2.5" onClick={() => setSidebarOpen(false)}>
                        <span className="sr-only">Close sidebar</span>
                        <XMarkIcon className="h-6 w-6 text-white" aria-hidden="true" />
                      </button>
                    </div>
                  </Transition.Child>
                  {/* Sidebar component, swap this element with another sidebar if you like */}
                  <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-stone-50 px-6 pb-4">
                    <div className="flex h-16 shrink-0 items-center">
                      <img
                        className="h-8 w-auto"
                        src={logo}
                        alt="Your Company"
                      />
                    </div>
                    <nav className="flex flex-1 flex-col">
                      <ul role="list" className="flex flex-1 flex-col gap-y-7">
                        <li>
                          <ul role="list" className="-mx-2 space-y-1">
                            {navigation.map((item) => (
                              <li key={item.name}>
                                <a
                                  href={item.href}
                                  onClick={() => handleNavigationChange(item.name)}
                                  className={classNames(
                                    item.current
                                      ? 'bg-[#F1DDC2] text-[#8D4304]'
                                      : 'text-gray-700 hover:text-[#8D4304] hover:bg-[#F1DDC2]',
                                    'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold'
                                  )}
                                >
                                  <item.icon
                                    className={classNames(
                                      item.current ? 'text-[#8D4304]' : 'text-gray-400 group-hover:text-[#8D4304]',
                                      'h-6 w-6 shrink-0'
                                    )}
                                    aria-hidden="true"
                                  />
                                  {item.name}
                                </a>
                              </li>
                            ))}
                          </ul>
                        </li>
               
                        <li className="mt-auto">
                         
                            <Cog6ToothIcon
                              className="h-6 w-6 shrink-0 text-gray-400 group-hover:text-[#8D4304]"
                              aria-hidden="true"
                            />
                           
                       
                        </li>
                      </ul>
                    </nav>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition.Root>

        {/* Static sidebar for desktop */}
        <div className="hidden lg:fixed lg:inset-y-0 lg:z-50 lg:flex lg:w-72 lg:flex-col">
          {/* Sidebar component, swap this element with another sidebar if you like */}
          <div className="flex grow flex-col gap-y-5 overflow-y-auto border-r border-gray-200 bg-stone-50 px-6 pb-4">
            <div className="flex h-40 shrink-0 items-center">
              <img
                className="h-12 w-auto"
                src={logo}
                alt="Your Company"
              />
              <span className='font-bold pl-4 text-[#8D4304] text-lg'>Admin</span>
            </div>
            <nav className="flex flex-1 flex-col">
              <ul role="list" className="flex flex-1 flex-col gap-y-7">
                <li>
                  <ul role="list" className="-mx-2 space-y-1">
                    {navigation.map((item) => (
                      <li key={item.name}>
                        <a
                          href={item.href}
                          onClick={() => handleNavigationChange(item.name)}
                          className={classNames(
                            item.current
                              ? 'bg-[#F1DDC2] text-[#8D4304]'
                              : 'text-gray-700 hover:text-[#8D4304] hover:bg-[#F1DDC2]',
                            'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold'
                          )}
                        >
                          <item.icon
                            className={classNames(
                              item.current ? 'text-[#8D4304]' : 'text-gray-400 group-hover:text-[#8D4304]',
                              'h-6 w-6 shrink-0'
                            )}
                            aria-hidden="true"
                          />
                          {item.name}
                        </a>
                      </li>
                    ))}
                  </ul>
                </li>
             
                <li className="mt-auto">

                    <Cog6ToothIcon
                      className="h-6 w-6 shrink-0 text-gray-400 group-hover:text-[#8D4304]"
                      aria-hidden="true"
                    />
                
                 
                </li>
              </ul>
            </nav>
          </div>
        </div>

        <div className="lg:pl-72">
          <div className="sticky top-0 z-10 flex h-2 shrink-0 items-center gap-x-4 border-b border-gray-200 bg-stone-50 px-4 shadow-sm sm:gap-x-6 sm:px-6 lg:px-8">
            <button type="button" className="-m-2.5 mt-16 p-4 text-gray-700 lg:hidden" onClick={() => setSidebarOpen(true)}>
              <span className="sr-only">Open sidebar</span>
              <Cog6ToothIcon className="h-10 w-10" aria-hidden="true" />
            </button>
          
          </div>

          <main >
          {renderCurrentComponent()} 
          </main>
        </div>
      </div>
    </>
  )
}
