import { Fragment, useEffect, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { ShareIcon, XMarkIcon, FingerPrintIcon, ArrowRightIcon } from '@heroicons/react/24/outline'
import { PencilIcon, PlusIcon } from '@heroicons/react/20/solid'
import { useData } from '../../../DataContext';
import LoginModal from '../../LoginModal';
import DeleteRecipe from './DeleteRecipe';


import { doc, updateDoc, arrayUnion, deleteDoc } from "firebase/firestore";
import { db } from '../../../firebase'; // Anta at dette er din Firebase-konfigurasjonsfil

export default function RecipeDetail({ recipe, onClose }: any) {
  const [open, setOpen] = useState(true);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const firebaseConsoleBaseUrl = "https://console.firebase.google.com/u/0/project/useless-411f7/firestore/data/~2Flistings~2F";
  const { userData } = useData();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false); 

  const currentUserID = localStorage.getItem('uid');

  const handleDelete = async () => {
    // Implementer slett-logikken her, f.eks.:
    await deleteDoc(doc(db, "recipes", recipe.id));
    setIsDeleteModalOpen(false); // Lukk modalen etter vellykket sletting
    // Legg til eventuell opprydding eller navigering her
  };

  const closeDialog = () => {
    setOpen(false);
    setTimeout(() => onClose(), 500);
  };

  const handleImageClick = () => {
    setCurrentImageIndex(prevIndex => 
      (prevIndex + 1) % recipe.photoURLs.length
    );
  };


  const handleEditClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation(); // Stopper event-bobling
    window.open(`${firebaseConsoleBaseUrl}${recipe.id}`, '_blank'); // Åpner Firebase URL i ny fane
  };

  const handleCart = async () => {
  const currentUserID = localStorage.getItem('uid'); // Hent brukerens UID fra localStorage
  if (!currentUserID) return; // Hvis det ikke finnes en UID, avslutt funksjonen

  const userDocRef = doc(db, "users", currentUserID); // Referanse til brukerens dokument i Firestore

  try {
    // Setter handlekurven til kun å inneholde det nye elementet
    // Dette erstatter alle tidligere elementer i handlekurven med det nye
    await updateDoc(userDocRef, {
      cart: [recipe] // Oppdaterer hele 'cart'-feltet med kun det nye elementet
    });
    window.location.href = "/checkout"
  } catch (error) {
    console.error("Kunne ikke oppdatere handlekurven", error);
    alert("Det oppstod en feil ved å legge til elementet i handlekurven.");
  }
};

  const isCreator = currentUserID === recipe.creatorId;

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={closeDialog}>
        <Transition.Child
          as={Fragment}
          enter="ease-in-out duration-500"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in-out duration-500"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>
        <LoginModal isOpen={isModalOpen} setIsOpen={setIsModalOpen} />
        <DeleteRecipe
        isOpen={isDeleteModalOpen}
        onClose={() => setIsDeleteModalOpen(false)}
        onDelete={handleDelete}
      />
        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <Dialog.Panel className="pointer-events-auto relative w-96">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-in-out duration-500"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in-out duration-500"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <div className="absolute left-0 top-0 -ml-8 flex pr-2 pt-4 sm:-ml-10 sm:pr-4">
                      <button
                        type="button"
                        className="relative rounded-md text-gray-300 hover:text-white focus:outline-none focus:ring-2 focus:ring-white"
                        onClick={closeDialog}
                      >
                        <span className="absolute -inset-2.5" />
                        <span className="sr-only">Lukk panel</span>
                        <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                      </button>
                    </div>
                  </Transition.Child>
                  <div className="h-full overflow-y-auto bg-stone-50 p-8">
                    <div className="space-y-6 pb-16">
                      <div>
                      <div className="aspect-h-7 aspect-w-10 block w-full overflow-hidden rounded-lg relative">
                      <img
                        src={recipe.photoURLs[currentImageIndex]}
                        alt={recipe.headline}
                        onClick={handleImageClick}
                        className="object-cover w-full h-full"
                      />
                      <div
                        className="absolute inset-0 flex items-start justify-end"
                        onClick={handleImageClick}
                      >
                        <FingerPrintIcon className="h-8 w-8 m-4 text-white pulse-animation lg:hidden" aria-hidden="true"></FingerPrintIcon>
                        <ArrowRightIcon className="hidden lg:block h-8 w-8 m-4 text-white pulse-animation"></ArrowRightIcon>
                      </div>
                    </div>
                        <div className="mt-4 flex items-start justify-between">
                          <div>
                            <h2 className="text-base font-semibold leading-6 text-stone-900">
                              <span className="sr-only">Detaljer for </span>{recipe.headline}
                              {userData.admin && (
                              <button
                                onClick={handleEditClick}
                                className="ml-2 inline-flex z-10 items-center rounded-full bg-blue-100 px-1.5 py-0.5 text-xs font-medium text-blue-700 hover:bg-blue-50"
                              >
                                Rediger
                              </button>
                            )}
                            </h2>
                            <p className="text-sm font-medium text-gray-500">{recipe.price} kr</p>
                          </div>
                          <button
                            type="button"
                            className="relative ml-4 flex h-8 w-8 items-center justify-center rounded-full bg-stone-50 text-gray-400 hover:bg-gray-100 hover:text-[#465745] focus:outline-none focus:ring-2 focus:ring-[#F1DDC2]"
                          >
                            <span className="absolute -inset-1.5" />
                            <ShareIcon className="h-6 w-6" aria-hidden="true" />
                            <span className="sr-only">Favorite</span>
                          </button>
                        </div>
                        
                      </div>
                      <div>
                        <h3 className="font-medium text-stone-900">Beskrivelse</h3>
                        <div className="mt-2 flex items-center justify-between">
                          <p className="text-sm italic text-gray-500">{recipe.about}</p>
                          {isCreator && (
                            <button
                              type="button"
                              className="relative -mr-2 flex h-8 w-8 items-center justify-center rounded-full bg-stone-50 text-gray-400 hover:bg-gray-100 hover:text-[#465745] focus:outline-none focus:ring-2 focus:ring-[#F1DDC2]"
                            >
                              <PencilIcon className="h-6 w-6" aria-hidden="true" />
                            </button>
                          )}
                        </div>
                      </div>
                      <div>
                        <h3 className="font-medium text-stone-900">Informasjon</h3>
                        <dl className="mt-2 divide-y divide-gray-200 border-b border-t border-gray-200">
                          <div className="flex justify-between py-3 text-sm font-medium">
                            <dt className="text-gray-500">Laget av</dt>
                            <dd className="text-stone-900">{recipe.creatorName ? recipe.creatorName:""}</dd>
                          </div>
                          <div className="flex justify-between py-3 text-sm font-medium">
                            <dt className="text-gray-500">Kategori</dt>
                            <dd className="text-stone-900">{recipe.mainCategory}</dd>
                          </div>
                          <div className="flex justify-between py-3 text-sm font-medium">
                            <dt className="text-gray-500">Nedlastbart innhold</dt>
                            <dd className="text-stone-900">PDF</dd>
                          </div>
                        </dl>
                      </div>

                      <div className="flex">
                        <button
                          type="button"
                          className="flex-1 rounded-md bg-[#F1DDC2] px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-gray-300 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-[#F1DDC2]"
                          onClick={closeDialog}
                       >
                          Tilbake
                        </button>
                        {isCreator ? (
                          <button
                            type="button"
                            className="ml-3 flex-1 rounded-md bg-red-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-700"
                            onClick={() => setIsDeleteModalOpen(true)}
                         >
                            Slett
                          </button>
                        ) : (
                          <button
                            type="button"
                            className="ml-3 flex-1 rounded-md bg-[#455644] px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-700"
                            onClick={() => {localStorage.getItem('uid')?handleCart():setIsModalOpen(true)}}
                          >
                             Kjøp
                          </button>
                        )}
                      </div>
                      <button
                            type="button"
                            className=" w-full rounded-md bg-[#884723] px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-gray-700"
                            onClick={()=>{window.location.href = "/creator/"+recipe.creatorId}}
                          >
                             Se mer fra {recipe.creatorName ? recipe.creatorName:""}
                          </button>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}
