import shirtImage from "../../images/icons/UseLess Icon T-shirt - Beige.png"
import React, { useState, useEffect } from 'react';
import { onSnapshot, collection, query, where } from "firebase/firestore";
import { db } from "../../firebase";
import ListingCard from "../profile/listing/ListingCard";
import ListingDetail from "../profile/listing/ListingDetail";
import Slider from "react-slick";

interface Listing {
  id: string;
  title: string;
  description: string;
  price: number;
  // Legg til andre felt basert på din Firestore-dokumentstruktur
}

export default function ListingSection() {
  const [selectedListing, setSelectedListing] = useState<Listing | null>(null);
  const [isDetailOpen, setIsDetailOpen] = useState(false);
  const [listings, setListings] = useState<Listing[]>([]);

  useEffect(() => {
    const fetchListings = () => {
      const q = query(collection(db, "listings"), where("isActive", "==", true));
      const unsubscribe = onSnapshot(q, (querySnapshot) => {
        const listingsData: Listing[] = [];
        querySnapshot.forEach((doc) => {
          listingsData.push({
            id: doc.id,
            ...(doc.data() as Omit<Listing, 'id'>)
          });
        });
        setListings(listingsData);
      });

      return unsubscribe;
    };

    const unsubscribe = fetchListings();
    return () => unsubscribe();
  }, []);

  const handleCardClick = (listing: Listing) => {
    setSelectedListing(listing);
    setIsDetailOpen(true);
  };

  const handleCloseDetail = () => {
    setIsDetailOpen(false);
    setSelectedListing(null);
  };

// Oppdater innstillinger basert på antallet oppskrifter
const settings = {
  infinite: listings.length > 3, // Bare aktiver infinite hvis det er mer enn 3 oppskrifter
  speed: 800,
  slidesToShow: Math.min(4, listings.length), // Vis maks 4 oppskrifter, eller færre hvis det ikke er nok
  slidesToScroll: Math.min(4, listings.length), // Scroll gjennom maks 4 oppskrifter, eller færre
  autoplay: listings.length > 3, // Bare aktiver autoplay hvis det er mer enn 3 oppskrifter
  autoplaySpeed: 3000,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: Math.min(2, listings.length), // Juster for responsivitet
        slidesToScroll: Math.min(2, listings.length),
        infinite: listings.length > 2,
        dots: true
      }
    },
    {
      breakpoint: 800,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: listings.length > 1, // Bare aktiver infinite hvis det er mer enn 1 oppskrift
      }
    }
  ]
};
  

    return (
        <>
 <div className="bg-stone-400 px-6 py-12 sm:py-16 lg:px-8">       
<div className="flex">
<div className="mx-auto max-w-2xl text-center">
  <h2 className="text-4xl font-bold tracking-tight text-stone-50 sm:text-6xl">Se hele utvalget av klær!</h2>
  <p className="mt-6 text-lg leading-8 text-stone-100">
  Alle designene på denne plattformen er laget av dyktige og engesjerte creators! Besøk deres butikk for å utforske flere av deres unike produkter!
  </p>
  <div className="flex justify-center mt-8 mb-12">

<a
  className="ml-4 rounded-md bg-[#F36699] px-8 py-3 text-lg font-semibold text-white shadow-sm  hover:bg-[#465745]"
  href="/listings"
>
  Se alle
</a>
</div>


</div>
<img src={shirtImage} className="w-14 h-14 sm:h-60 sm:w-60 mr-auto"/>


</div>
<div className="mx-2">
<Slider {...settings}>
          {listings.map((listing) => (
            <div className="p-0 sm:p-4 recipe-card" key={listing.id}>
              <ListingCard listing={listing} onCardClick={() => handleCardClick(listing)} />
            </div>
          ))}
        </Slider>
        </div>
              </div>
              {isDetailOpen && <ListingDetail listing={selectedListing} onClose={handleCloseDetail} />}
</>
    )
}