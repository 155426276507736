export default function Overview() {
    return (
        <>
        <div className="min-h-full">
          <div className="bg-[#465745] pb-32">
            <header className="py-10">
              <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
                <h1 className="text-3xl font-bold tracking-tight text-white">Oversikt</h1>
                {/* Legg til en knapp for å vise CreatorApplicationList */}
              <button
           
                className="mt-4 rounded-md bg-[#F1DDC2] py-2 px-4 text-sm font-bold text-[#884723] hover:bg-gray-100"
              >
                {'Knapp'}
              </button>
              </div>
            </header>
          </div>
  
          <main className="-mt-32 flex">
            
            <div className="mx-auto max-w-7xl px-4 pb-12 sm:px-6 lg:px-8">
              <div className="rounded-lg bg-stone-50 px-5 py-6 shadow sm:px-6">
  
            
  
              </div>
            </div>
          </main>
        </div>
      </>
    )
  }
  