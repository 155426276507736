import { useData } from '../DataContext';


  
  export default function ConfirmationPage() {
    const { userData } = useData();
    console.log(userData)

    if (!userData.lastOrder || !userData.lastOrder.cart) {
        return <div>Laster ordredetaljer...</div>; // Eller en annen passende placeholder
      }

    return (
      <>
        <main className="relative lg:min-h-full">
          <div className="h-80 overflow-hidden lg:absolute lg:h-full lg:w-1/2 lg:pr-4 xl:pr-12">
            <img
              src="https://tailwindui.com/img/ecommerce-images/confirmation-page-06-hero.jpg"
              alt="TODO"
              className="h-full w-full object-cover object-center rounded-3xl m-6"
            />
          </div>
  
          <div>
            <div className="mx-auto max-w-2xl px-4 py-16 sm:px-6 sm:py-24 lg:grid lg:max-w-7xl lg:grid-cols-2 lg:gap-x-8 lg:px-8 lg:py-32 xl:gap-x-24">
              <div className="lg:col-start-2">
                <h1 className="text-sm font-medium text-[#F36699]">Betaling fullført</h1>
                <p className="mt-2 text-4xl font-bold tracking-tight text-stone-900 sm:text-5xl">Takk for din bestilling</p>
                <p className="mt-2 text-base text-gray-500">
                 Vi setter pris på din ordre, og jobber med å prosessere den. Vent et øyeblikk så sender vi deg bekreftelse veldig snart!
                  
                </p>
  
                <dl className="mt-16 text-sm font-medium">
                  <dt className="text-stone-900">Bestillingsreferanse</dt>
                  <dd className="mt-2 text-[#F36699]" >{userData.orderHistory[userData.orderHistory.length-1].orderId}</dd>
                </dl>
  
                <ul
                  role="list"
                  className="mt-6 divide-y divide-gray-200 border-t border-gray-200 text-sm font-medium text-gray-500"
                >
                  {userData.lastOrder.cart.map((product: any) => (
                    <li key={product.id} className="flex space-x-6 py-6">
                      <img
                        src={product.photoURLs[0]}
                        className="h-24 w-24 flex-none rounded-md bg-gray-100 object-cover object-center"
                      />
                      <div className="flex-auto space-y-1">
                        <h3 className="text-stone-900">
                          <a href={product.href}>{product.headline}</a>
                        </h3>
                        <p>{product.about}</p>
                        <p>{product.mainCategory}</p>
                      </div>
                      <p className="flex-none font-medium text-stone-900">{product.price} kr</p>
                    </li>
                  ))}
                </ul>
  
                <dl className="space-y-6 border-t border-gray-200 pt-6 text-sm font-medium text-gray-500">
                  
  
                  <div className="flex justify-between">
                    <dt>Frakt</dt>
                    <dd className="text-stone-900">79</dd>
                  </div>
  
                  <div className="flex justify-between">
                    <dt>MVA</dt>
                    <dd className="text-stone-900">-</dd>
                  </div>
  
                  <div className="flex items-center justify-between border-t border-gray-200 pt-6 text-stone-900">
                    <dt className="text-base">Total</dt>
                    <dd className="text-base">{userData.lastOrder.cart.reduce((total: any, item: any) => {
    return total + (Number(item.price) || 0); // Legger til item.price til total, eller 0 hvis item.price ikke er definert
  }, 0)} NOK</dd>
                  </div>
                </dl>
  
                <dl className="mt-16 grid grid-cols-2 gap-x-4 text-sm text-gray-600">
                  <div>
                    <dt className="font-medium text-stone-900">Leveringsadresse</dt>
                    <dd className="mt-2">
                      <address className="not-italic">
                        <span className="block">{userData.lastOrder.shippingInfo ? userData.lastOrder.shippingInfo.name:""}</span>
                        <span className="block">{userData.lastOrder.shippingInfo.address.line1}</span>
                        <span className="block">{userData.lastOrder.shippingInfo.address.postal_code+" "+userData.lastOrder.shippingInfo.address.city}</span>
                      </address>
                    </dd>
                  </div>
                  <div>
                    <dt className="font-medium text-stone-900">Betalingsinformasjon</dt>
                    <dd className="mt-2 space-y-2 sm:flex sm:space-x-4 sm:space-y-0">
                      <div className="flex-none">
                        <svg aria-hidden="true" width={36} height={24} viewBox="0 0 36 24" className="h-6 w-auto">
                          <rect width={36} height={24} rx={4} fill="#224DBA" />
                          <path
                            d="M10.925 15.673H8.874l-1.538-6c-.073-.276-.228-.52-.456-.635A6.575 6.575 0 005 8.403v-.231h3.304c.456 0 .798.347.855.75l.798 4.328 2.05-5.078h1.994l-3.076 7.5zm4.216 0h-1.937L14.8 8.172h1.937l-1.595 7.5zm4.101-5.422c.057-.404.399-.635.798-.635a3.54 3.54 0 011.88.346l.342-1.615A4.808 4.808 0 0020.496 8c-1.88 0-3.248 1.039-3.248 2.481 0 1.097.969 1.673 1.653 2.02.74.346 1.025.577.968.923 0 .519-.57.75-1.139.75a4.795 4.795 0 01-1.994-.462l-.342 1.616a5.48 5.48 0 002.108.404c2.108.057 3.418-.981 3.418-2.539 0-1.962-2.678-2.077-2.678-2.942zm9.457 5.422L27.16 8.172h-1.652a.858.858 0 00-.798.577l-2.848 6.924h1.994l.398-1.096h2.45l.228 1.096h1.766zm-2.905-5.482l.57 2.827h-1.596l1.026-2.827z"
                            fill="#fff"
                          />
                        </svg>
                        <p className="sr-only">Visa</p>
                      </div>
                      <div className="flex-auto">
                        <p className="text-stone-900">Slutter på 4242</p>
                        <p>Går ut 12 / 21</p>
                      </div>
                    </dd>
                  </div>
                </dl>
  
                <div className="mt-16 border-t border-gray-200 py-6 text-right">
                  <a href="/" className="text-sm font-medium text-[#F36699] hover:text-gray-500">
                    Fortsett å handle
                    <span aria-hidden="true"> &rarr;</span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </main>
      </>
    )
  }
  