

export default function CourseSection() {

  return (
    <div className="bg-stone-50">
      <div className="relative isolate overflow-hidden bg-gradient-to-b from-pink-100/20 ">
        <div
          className="absolute inset-y-0 right-1/2 -z-10 -mr-96 w-[200%] origin-top-right skew-x-[-30deg] bg-stone-50 shadow-xl shadow-pink-600/10 ring-1 ring-indigo-50 sm:-mr-80 lg:-mr-96"
          aria-hidden="true"
        />
        <div className="mx-auto max-w-7xl px-6 py-32 sm:py-40 lg:px-8">
          <div className="mx-auto max-w-2xl lg:mx-0 lg:grid lg:max-w-none lg:grid-cols-2 lg:gap-x-16 lg:gap-y-6 xl:grid-cols-1 xl:grid-rows-1 xl:gap-x-8">
            <h1 className="pr-28 max-w-2xl text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl lg:col-span-2 xl:col-auto">
              Vi har lansert sykurs - fra drøm til søm.
            </h1>
            <div className="mt-6 max-w-xl lg:mt-0 xl:col-end-1 xl:row-start-1">
              <p className="text-lg leading-8 text-gray-600">
                Her kan du lære å ...
              </p>
              <div className="mt-16 flex items-center gap-x-6">
                <a
                  href="/course/JO3Kn3e366c4D0nrFjSx"
                  className="rounded-md bg-[#F36699] my-8 px-8 py-4 text-md font-semibold text-white shadow-sm hover:bg-[#465745] focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                >
                  Ta meg til kurset <span aria-hidden="true">→</span>
                </a>
               
              </div>
            </div>
            <img
              src="https://firebasestorage.googleapis.com/v0/b/useless-411f7.appspot.com/o/courses%2FsowCourse%2FSkjermbilde%202024-03-11%20kl.%2015.01.37.png?alt=media&token=75b8c3f8-adfc-4fc2-8d6f-4cd0a9e8b38f"
              alt=""
              className="aspect-[6/5] w-full max-w-lg rounded-2xl object-cover  lg:mt-0 lg:max-w-none xl:row-span-2 xl:row-end-2 "
            />
          </div>
        </div>
        <div className="absolute inset-x-0 bottom-0 -z-10 h-24 bg-gradient-to-t from-white sm:h-32" />
      </div>
    </div>
  )
}