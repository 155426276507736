// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";
import { getAuth, GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import { getStorage } from "firebase/storage"; // Legg til denne linjen

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyArM5StFJ0GPqs_sHV_sTMOEYsWUGMy8nY",
  authDomain: "useless-411f7.firebaseapp.com",
  projectId: "useless-411f7",
  storageBucket: "useless-411f7.appspot.com",
  messagingSenderId: "445854729648",
  appId: "1:445854729648:web:48154e8e95227c31b18275"
};


// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const db = getFirestore(app);
const auth = getAuth(app);
const storage = getStorage(app); // Legg til denne linjen
const provider = new GoogleAuthProvider();

export { db, app, auth, provider, storage };
