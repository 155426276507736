import { useState } from 'react'
import { Dialog, Switch } from '@headlessui/react'
import { Bars3Icon } from '@heroicons/react/20/solid'
import {
  BellIcon,
  CreditCardIcon,
  CubeIcon,
  FingerPrintIcon,
  UserCircleIcon,
  UsersIcon,
  XMarkIcon,
  ArrowRightStartOnRectangleIcon
} from '@heroicons/react/24/outline'
import { useData } from '../DataContext';
import Personal from '../components/profile/Personal';
import MyListings from '../components/profile/listing/MyListings'; 
import MyRecipes from '../components/profile/recipe/MyRecipes'; 
import SalesOverview from '../components/profile/SalesOverview'; 
import PurchaseHistory from '../components/profile/PurchaseHistory'; 
import MyCourses from '../components/profile/MyCourses'; 




function classNames(...classes: any) {
  return classes.filter(Boolean).join(' ')
}

export default function ProfilePage() {
  const { userData, userId, setUserId } = useData();
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false)
  const [currentNavItem, setCurrentNavItem] = useState('Personlig'); // Lagre gjeldende valg


  const secondaryNavigation = [
    { name: 'Personlig', href: '#', icon: UserCircleIcon, current: true },
    { name: 'Kjøpshistorikk', href: '#', icon: CreditCardIcon, current: false },
    { name: 'Aktive kurs', href: '#', icon: UsersIcon, current: false },
    ...(localStorage.getItem("creator")=="true" ? [
      { name: 'Mine listings', href: '#', icon: CubeIcon, current: false },
      { name: 'Mine oppskrifter', href: '#', icon: FingerPrintIcon, current: false },
      { name: 'Salgsoversikt', href: '#', icon: Bars3Icon, current: false }
    ] : []),
  ];


  // Funksjon for å håndtere endring i navigasjonsmenyen
  const handleNavigationChange = (name: string) => {
    setCurrentNavItem(name);
  }

  // Velg riktig komponent basert på gjeldende valg
  const renderCurrentComponent = () => {
    switch(currentNavItem) {
      case 'Personlig':
        return <Personal />;
      case 'Mine listings':
        return <MyListings />;
      case 'Mine oppskrifter':
        return <MyRecipes />;
      case 'Salgsoversikt':
        return <SalesOverview />;
      case 'Kjøpshistorikk':
        return <PurchaseHistory />;
      case 'Aktive kurs':
        return <MyCourses />;
      default:
        return null;
    }
  }

  const handleLogout = () => {
    // Fjerner brukerens ID fra localStorage
    localStorage.removeItem('uid');
    localStorage.removeItem('creator');
    localStorage.removeItem('admin');

    // Omdirigerer til autentiseringssiden
    window.location.href = '/';
  };

  return (
    <>
      <div className="mx-auto max-w-7xl lg:flex lg:gap-x-16 lg:px-8">
        <aside className="flex overflow-x-auto border-b border-gray-900/5 py-4 lg:block lg:w-64 lg:flex-none lg:border-0 lg:py-20">
          <nav className="flex-none px-4 sm:px-6 lg:px-0">
            <ul role="list" className="flex gap-x-3 gap-y-1 whitespace-nowrap lg:flex-col">
              {secondaryNavigation.map((item) => (
                <li key={item.name}>
                  <a
                    href={item.href}
                    onClick={() => handleNavigationChange(item.name)} // Legg til onClick-håndterer for å endre valg
                    className={classNames(
                      currentNavItem === item.name
                        ? 'bg-gray-50 text-[#8D4304]'
                        : 'text-gray-700 hover:text-[#8D4304] hover:bg-gray-50',
                      'group flex gap-x-3 rounded-md py-2 pl-2 pr-3 text-sm leading-6 font-semibold'
                    )}
                  >
                    <item.icon
                      className={classNames(
                        currentNavItem === item.name ? "text-[#8D4304]" : 'text-gray-400 group-hover:text-[#465745]',
                        'h-6 w-6 shrink-0'
                      )}
                      aria-hidden="true"
                    />
                    {item.name}
                  </a>
                </li>
              ))}
                <li className="flex border-t border-gray-100 pt-6 pl-3">
  <ArrowRightStartOnRectangleIcon
          className={classNames(
            'text-gray-400 group-hover:text-[#465745]',
            'h-6 w-6 shrink-0',
          )}
          aria-hidden="true"
        />
        <button
          type="button"
          className="text-sm font-semibold leading-6 text-[#8D4304] hover:text-[#465745] pl-2"
          onClick={handleLogout} // Legg til onClick event her
        >
          Logg ut
        </button>
        </li>
            </ul>
          </nav>
        </aside>

        {renderCurrentComponent()} {/* Render riktig komponent basert på gjeldende valg */}
      </div>
    </>
  )
}