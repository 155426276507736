import React, { useEffect } from 'react';
import logo from './logo.svg';
import './App.css';
import { BrowserRouter, Routes, Route, Link } from "react-router-dom";
import Home from "./pages/Home";
import ProfilePage from "./pages/ProfilePage";
import AboutPage from "./pages/AboutPage";
import AdminPage from "./pages/AdminPage";
import NavBar from "./components/NavBar";
import Footer from "./components/Footer";
import { useData } from './DataContext';
import ListingPage from './pages/ListingPage'; 
import RecipePage from './pages/RecipePage'; 
import BlogPage from './pages/BlogPage'; 
import TermsPage from './pages/TermsPage'; 
import CheckoutPage from './pages/CheckoutPage'; 
import ConfirmationPage from './pages/ConfirmationPage'; 
import CreatorShopPage from './pages/CreatorShopPage';
import CoursePage from './pages/CoursePage';

function App() {
  const { userData, setUserData, userId, setUserId  } = useData();

  useEffect(() => {
    const storedUserId = localStorage.getItem('uid');
    if (storedUserId) {
      setUserId(storedUserId);
    }
  }, [setUserId]);


  return (
    <div className='bg-stone-50'>
    <NavBar></NavBar>

<BrowserRouter>
      <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/about" element={<AboutPage />} />
      <Route path="/profile" element={<ProfilePage />} />
      <Route path="/admin" element={<AdminPage />} />
      <Route path="/listings" element={<ListingPage />} />
      <Route path="/recipes" element={<RecipePage />} />
      <Route path="/blog" element={<BlogPage />} />
      <Route path="/terms" element={<TermsPage />} />
      <Route path="/checkout" element={<CheckoutPage />} />
      <Route path="/confirmation" element={<ConfirmationPage />} />
      <Route path="/course/:courseId" element={<CoursePage />} />
      <Route path="/creator/:creatorId" element={<CreatorShopPage />}/>
      </Routes>
</BrowserRouter>  

    <Footer></Footer>
</div>
  );
}

export default App;
