import { Fragment, useRef, useState, useEffect } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { CheckIcon } from '@heroicons/react/24/outline'
import { PhotoIcon } from '@heroicons/react/24/solid';
import  { db, storage } from "../../../firebase"
import { doc, setDoc, collection, addDoc } from "firebase/firestore";
import { ref as storageRef, uploadBytes, getDownloadURL } from "firebase/storage";
import { useData } from '../../../DataContext';

export default function AddListing({ onClose }: { onClose: () => void }) {
  const [open, setOpen] = useState(true)
  const [uploadedImages, setUploadedImages] = useState<string[]>([]);
  const [isDragOver, setIsDragOver] = useState(false);
  const { userData } = useData();
  const [sizes, setSizes] = useState<any[]>([]);

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      const target = e.target as HTMLInputElement; // Type assertion
      handleAddSize(target.value);
    }
  };

const handleAddSize = (size: any) => {
  setSizes((prevSizes) => [...prevSizes, size]);
};

const handleRemoveSize = (index: any) => {
  setSizes((prevSizes) => prevSizes.filter((_, i) => i !== index));
};

  const cancelButtonRef = useRef(null)


  const closeDialog = () => {
    setOpen(false);
    setTimeout(() => onClose(), 500);
  };

const handleDragOver = (e: any) => {
  e.preventDefault();
  setIsDragOver(true); 
};

const handleDragLeave = (e: any) => {
  e.preventDefault();
  setIsDragOver(false);
};

const handleDrop = (e: any) => {
  e.preventDefault();
  setIsDragOver(false);
  handleFileChange(e.dataTransfer.files);
};


  const handleFileChange = async (files: any) => {
    for (const file of files) {
      if (file.size > 0) {
        try {
          const fileRef = storageRef(storage, `listings/${file.name}`);
          const uploadResult = await uploadBytes(fileRef, file);
          const photoURL = await getDownloadURL(uploadResult.ref);
  
          setUploadedImages(prevImages => [...prevImages, photoURL]);
        } catch (error) {
          console.error("Error uploading file: ", error);
        }
      }
    }
  };
  

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
  
    const newListing = {
      headline: data.get('headline'),
      mainCategory: data.get('mainCategory'),
      about: data.get('about'),
      price: data.get('price'),
      stock: data.get('stock'),
      photoURLs: uploadedImages,
      creatorId: localStorage.getItem('uid'),
      creatorName: userData.name,
      creatorImage: userData.creatorApplication.imageURL,
      step: 0,
      sizes: sizes,
    };
  
    try {
      await addDoc(collection(db, "listings"), newListing);
      closeDialog();
    } catch (error) {
      console.error("Error adding document: ", error);
    }
  };

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={closeDialog}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-stone-50 px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
              <form onSubmit={handleSubmit}>
      <div className="space-y-8">
          <h2 className=" font-semibold leading-7 text-2xl text-gray-500">Opprett en ny listing</h2>

         

          <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">
            <div>
              <label htmlFor="headline" className="block text-sm font-medium leading-6 text-stone-900">
                Overskrift
              </label>
              <div className="mt-2">
                <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                  <input
                    type="text"
                    name="headline"
                    id="headline"
                    autoComplete="headline"
                    className="block flex-1 border-0 bg-transparent py-1.5 pl-2 text-stone-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                    placeholder=" ..."
                  />
                </div>
              </div>
              <p className="mt-3 text-sm leading-6 text-gray-500">Beskriv varen kort.</p>
            </div>

            <div>
            <label htmlFor="mainCategory" className="block text-sm font-medium leading-6 text-stone-900">
              Hovedkategori
            </label>
            <div className="mt-2">
              <select
                id="mainCategory"
                name="mainCategory"
                autoComplete="mainCategory"
                className="block w-full p-2 rounded-md border-gray-300 shadow-sm  ring-1 ring-inset ring-gray-300 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
              >
                <option value="">Velg en kategori</option>
                <option value="Bukse">Bukse</option>
                <option value="Skjørt">Skjørt</option>
                <option value="Genser">Genser</option>
                <option value="Topper">Topper</option>
                <option value="Kjole">Kjole</option>
                <option value="Jakke">Jakke</option>
                <option value="Accessories">Accessories</option>
              </select>
            </div>
          </div>
          </div>

            <div className="col-span-full">
              <label htmlFor="about" className="block text-sm font-medium leading-6 text-stone-900">
                Beskrivelse
              </label>
              <div className="mt-2">
                <textarea
                  id="about"
                  name="about"
                  rows={3}
                  className="block pl-2 w-full rounded-md border-0 py-1.5 text-stone-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  defaultValue={''}
                />
              </div>
              <p className="mt-3 text-sm leading-6 text-gray-500">Skriv et par setninger om produktet.</p>
            </div>

            <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">
            <div>
        <label htmlFor="price" className="block text-sm font-medium leading-6 text-stone-900">
          Pris
        </label>
        <div className="mt-2">
          <input
            type="number"
            name="price"
            id="price"
            autoComplete="price"
            className="block w-full p-3 rounded-md border-0 py-1.5 text-stone-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
            placeholder="Angi pris"
          />
        </div>
      </div>

      <div >
        <label htmlFor="stock" className="block text-sm font-medium leading-6 text-stone-900">
          Antall på lager
        </label>
        <div className="mt-2">
          <input
            type="number"
            name="stock"
            id="stock"
            autoComplete="stock"
            className="block w-full p-3 rounded-md border-0 py-1.5 text-stone-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
            placeholder="Antall tilgjengelig"
          />
        </div>
      </div>
      </div>

      <div>
  <label htmlFor="size" className="block text-sm font-medium text-gray-700">
    Størrelser
  </label>
  <div className="mt-1">
    <input
      type="text"
      name="size"
      id="size"
      className="block w-full p-3 rounded-md border-0 py-1.5 text-stone-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
      placeholder="Legg til størrelse og trykk enter"
      onKeyDown={handleKeyDown}
    />
  </div>
  <div className="mt-2">
    {sizes.map((size, index) => (
      <span key={index} className="mr-2 p-1 bg-gray-200 rounded">{size} <button onClick={() => handleRemoveSize(index)}>x</button></span>
    ))}
  </div>
</div>

            <div className="col-span-full">
              <label htmlFor="cover-photo" className="block text-sm font-medium leading-6 text-stone-900">
                Bilder
              </label>
                            <div className="space-y-4 flex">
                {uploadedImages.map((url, index) => (
                    <img key={index} src={url} alt="Opplastet bilde" className="w-10 h-10 m-2 rounded-md max-w-xs" />
                ))}
                </div>      
                                    <div 
                    onDragOver={handleDragOver}
                    onDragLeave={handleDragLeave}
                    onDrop={handleDrop}
                    className={`drop-zone ${isDragOver ? 'bg-gray-100 rounded-md' : ''}`}
                    >
              <div className="mt-2 flex justify-center rounded-lg border border-dashed border-gray-900/25 px-6 py-10">
                <div className="text-center">
                  <PhotoIcon className="mx-auto h-12 w-12 text-gray-300" aria-hidden="true" />
                  <div className="mt-4 flex text-sm leading-6 text-gray-600">
                    <label
                      htmlFor="file-upload"
                      className="relative cursor-pointer rounded-md bg-stone-50 font-semibold text-indigo-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-600 focus-within:ring-offset-2 hover:text-[#465745]"
                    >
                      <span>Last opp en fil</span>
                      <input
                        id="file-upload"
                        name="file-upload"
                        type="file"
                        multiple
                        className="sr-only"
                        onChange={(e) => handleFileChange(e.target.files)}
                        />
                    </label>
                    <p className="pl-1">eller dra og slipp</p>
                  </div>
                  <p className="text-xs leading-5 text-gray-600">PNG, JPG, GIF opp til 10MB</p>
                </div>
              </div>
              </div>
            </div>
          </div>


        <div className="pt-5">
        <div className="flex justify-end">
          <button
            type="button"
            onClick={closeDialog}
            className="bg-stone-50 py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#465745]"
          >
            Avbryt
          </button>
          <button
            type="submit"
            className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-[#F36699] hover:bg-[#465745] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#465745]"
          >
            <CheckIcon className="mx-auto h-5 w-5 text-gray-300 mr-1" aria-hidden="true" /> Opprett listing
          </button>
        </div>
      </div>
        </form>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}