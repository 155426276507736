import { Fragment, useRef, useState, useEffect } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { CheckIcon } from '@heroicons/react/24/outline'
import { collection, query, where, getDocs, doc, updateDoc } from 'firebase/firestore';
import { db } from '../../firebase';
import { getFunctions, httpsCallable } from 'firebase/functions';

interface CreatorApplication {
  id: string;
  name: string;
  email: string;
  creatorBio: string;
  age: string;
  imageURL: string;
  location: string;
  otherComments: string;
  reason: string;
  recipe: boolean;
  socialMedia: string;
  sow: boolean;
  approved: boolean;
}

interface CreatorApplicationListProps {
  applications: CreatorApplication[]; // Bruk denne typen for å definere typen for props
}

export default function CreatorApplicationList({ applications }: CreatorApplicationListProps) {
  const [open, setOpen] = useState(true);
  const [selectedApplicationIndex, setSelectedApplicationIndex] = useState<number | null>(null);
  const [apps, setApps] = useState<CreatorApplication[]>([]); // Oppdatert til å bruke state for apps

  const approveApplication = async (applicationId: string, userEmail: string) => {

    const userDocRef = doc(db, "users", applicationId);
    
    try {
      // Oppdaterer creator-feltet i Firestore
      await updateDoc(userDocRef, {
        "creator": true
      });
  
      // Kaller createCreator Firebase Function
      const functions = getFunctions(db.app, 'europe-west1'); // Initialiserer Firebase Functions
      const createCreator = httpsCallable(functions, 'createCreator'); // Refererer til din cloud-funksjon
      await createCreator({ email: userEmail, uid: applicationId }); // Passer inn nødvendig data
  
      alert("Søknaden er godkjent og creator-konto er opprettet!");
  
      // Oppdater UI som nødvendig
      setApps(prevApps => prevApps.map(app => app.id === applicationId ? { ...app, approved: true } : app));
    } catch (error) {
      console.error("Kunne ikke godkjenne søknaden eller opprette creator-konto: ", error);
      alert("Det oppstod en feil ved godkjenning av søknaden eller opprettelse av creator-konto.");
    }
  };
  

  const handleViewClick = (index: any) => {
    if (selectedApplicationIndex === index) {
      // Lukk detaljvisning hvis samme søknad er valgt igjen
      setSelectedApplicationIndex(null);
    } else {
      // Åpne detaljvisning for valgt søknad
      setSelectedApplicationIndex(index);
    }
  };

  // Hent CreatorApplications når komponenten mounts
  useEffect(() => {
    const fetchApplications = async () => {
      const q = query(collection(db, "users"), where("creatorApplication", "!=", null));
      const querySnapshot = await getDocs(q);
      const fetchedApps = querySnapshot.docs.map(doc => {
        // Inkluderer dokumentets ID i hvert objekt sammen med eksisterende data
        return {
          id: doc.id, // Dokumentets ID
          ...doc.data().creatorApplication, // Spreder dataene fra creatorApplication
          approved: doc.data().creator, // Antar at 'creator'-feltet angir om søknaden er godkjent
          email: doc.data().email,
          name: doc.data().name 
        }
      }) as CreatorApplication[];
      setApps(fetchedApps); // Oppdaterer state med den nye listen
    };
  
    fetchApplications();
  }, []);
  

  const cancelButtonRef = useRef(null)

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-[#F1DDC2] px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
              <div>
                <h2 className="font-bold text-[#8D4304]">Creator-søknader</h2>
                <ul role="list" className="divide-y divide-gray-100">
  {apps.map((app, index) => (
    <li key={index} className="bg-stone-50 rounded-md p-4 mt-2 flex flex-col items-start justify-between gap-x-6 py-5">
      <div className="flex min-w-0 gap-x-4">
        {/* Betinget rendering for å sjekke eksistensen av imageURL */}
        {app.imageURL ? (
          <img className="h-12 w-12 flex-none rounded-full bg-gray-50" src={app.imageURL} alt="" />
        ) : (
          <div className="h-12 w-12 flex-none rounded-full bg-gray-50" />
        )}
        <div className="min-w-0 flex-auto">
          {/* Sjekker at creatorApplication eksisterer før tilgang til dens egenskaper */}
          <p className="text-sm font-semibold leading-6 text-stone-900">{app.name} <span className="ml-2 inline-flex items-center rounded-md bg-gray-400/10 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20">
        {app.approved?"Godkjent":"Venter"}
      </span>
      <button
        onClick={() => handleViewClick(index)}
        className="ml-3  rounded-full bg-stone-50 px-2.5 py-1 text-xs font-semibold text-stone-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
      >
        {selectedApplicationIndex === index ? 'Skjul' : 'Vis'}
      </button>
      </p>
          
          <p className="mt-1 truncate text-xs leading-5 text-gray-500">{app.reason}</p>
        </div>
      </div>
      {selectedApplicationIndex === index && (
        <div className="mt-4 text-sm text-gray-700">
          {/* Sjekker at creatorApplication eksisterer før tilgang til dens egenskaper */}
          <p><strong>Bio:</strong> {app.creatorBio}</p>
          <p><strong>Alder:</strong> {app.age}</p>
          <p><strong>Lokasjon:</strong> {app.location}</p>
          <p><strong>Sosiale Medier:</strong> {app.socialMedia}</p>
          <p><strong>Kommentarer:</strong> {app.otherComments}</p>
          <p><strong>E-post:</strong> {app.email}</p>
          {/* Legg til flere detaljer her */}
          {/* Godkjenn-knappen */}
          {!app.approved && (
            <button
            onClick={() => approveApplication(app.id, app.email)}
              className="mt-3 inline-flex items-center rounded-md bg-gray-400/10 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20 hover:bg-green-300"
            >
              Godkjenn
            </button>
          )}
        </div>
      )}
    </li>
  ))}
</ul>
    </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}