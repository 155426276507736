import React, { useState, useEffect } from 'react';
import { Dialog } from '@headlessui/react';
import { Bars3Icon, XMarkIcon, Cog6ToothIcon, ShoppingBagIcon } from '@heroicons/react/24/outline';
import uselessLogo from "../images/uselessLogo.svg";
import LoginModal from './LoginModal';
import SignUpModal from './SignUpModal'; 
import { useData } from '../DataContext';

const navigation = [
  { name: 'Hjem', href: '/' },
  { name: 'Vårt community', href: 'https://www.facebook.com/groups/358228306954053' },
  { name: 'Blogg', href: '/blog' },
  { name: 'Om oss', href: '/about' },
  { name: 'Admin', href: '/admin' },
];

export default function NavBar() {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isSignUpModalOpen, setIsSignUpModalOpen] = useState(false);
  const { userData, userId, setUserId } = useData();

  useEffect(() => {
    const storedUserId = localStorage.getItem('uid');
    if (storedUserId) {
      setUserId(storedUserId);
    }
  }, [setUserId]);

  return (
    <header className="bg-stone-50">
      <LoginModal isOpen={isModalOpen} setIsOpen={setIsModalOpen} />
      <SignUpModal isOpen={isSignUpModalOpen} setIsOpen={setIsSignUpModalOpen} />
      <nav className="mx-auto flex max-w-7xl items-center justify-between gap-x-6 p-6 lg:px-8" aria-label="Global">
        <div className="flex lg:flex-1">
          <a href="/" className="-m-1.5 p-1.5">
            <span className="sr-only">Your Company</span>
            <img className="h-20 w-auto" src={uselessLogo} alt="" />
          </a>
        </div>
        <div className="hidden lg:flex lg:gap-x-12">
          {navigation.map((item) => {
            // Sjekker om menyelementet er 'Admin' og om brukeren er admin
            if (item.name === 'Admin' && !userData.admin) {
              return null; // Returnerer null hvis brukeren ikke er admin, noe som hindrer at elementet vises
            }

            return (
              <a key={item.name} href={item.href} className="text-sm  font-semibold leading-6 text-stone-900 hover:text-[#F36699]">
                <div className='flex'>
                  {item.name === 'Admin' ? <Cog6ToothIcon
                          className="h-6 w-6 pr-1 shrink-0 text-gray-400 group-hover:text-[#8D4304]"
                          aria-hidden="true"
                        /> : ""}
                  {item.name}
                </div>
              </a>
            );
          })}
        </div>
        <div className="flex flex-1 items-center justify-end gap-x-6">
          {!localStorage.getItem("uid") ? (
            <>
            <a onClick={() => setIsModalOpen(true)} className="hidden cursor-pointer lg:block lg:text-sm lg:font-semibold lg:leading-6 lg:text-stone-900">
              Logg inn
            </a>
            <a
              onClick={() => setIsSignUpModalOpen(true)}
              className="rounded-md bg-[#F36699] cursor-pointer px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-[#465745] focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-[#F36699]"
            >
              Lag bruker
            </a>
          </>
            
          ) : (
            <>
            {userData.cart && userData.cart.length>0 ?
            <a href="/checkout" className="group -m-2 flex items-center p-2">
            <ShoppingBagIcon
              className="h-6 w-6 flex-shrink-0 text-[#F36699] group-hover:text-gray-400"
              aria-hidden="true"
            />
            <span className="ml-2 text-sm font-medium text-gray-700 group-hover:text-gray-800">{""}</span>
            <span className="sr-only">items in cart, view bag</span>
          </a>:""}

            <a href="/profile" className="flex items-center gap-x-4 px-6 py-3 text-sm font-semibold leading-6 rounded-3xl hover:bg-pink-50 hover:text-[#F36699]">
              {userData.profileImage ? (
              <img
                className="h-8 w-8 rounded-full bg-gray-800"
                src={userData.profileImage || ""}
                alt=""
              />
              ):""}
              <span className="sr-only">Din profil</span>
              <span aria-hidden="true">{userData.name || ""}</span>
            </a>
            </>
          )}
        </div>
        <div className="flex lg:hidden">
          <button
            type="button"
            className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
            onClick={() => setMobileMenuOpen(true)}
          >
            <span className="sr-only">Open main menu</span>
            <Bars3Icon className="h-6 w-6" aria-hidden="true" />
          </button>
        </div>
      </nav>
      <Dialog as="div" className="lg:hidden" open={mobileMenuOpen} onClose={setMobileMenuOpen}>
        <div className="fixed inset-0 z-10" />
        <Dialog.Panel className="fixed inset-y-0 right-0 z-10 w-full overflow-y-auto bg-stone-50 px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
          <div className="flex items-center gap-x-6">
            <a href="#" className="-m-1.5 p-1.5">
              <span className="sr-only">Useless</span>
              <img
                className="h-8 w-auto"
                src={uselessLogo}
                alt=""
              />
            </a>
            <a
              onClick={() => setIsSignUpModalOpen(true)}
              className="ml-auto rounded-md bg-[#F36699] cursor-pointer px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-[#465745] focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-[#F36699]"
            >
              Lag bruker
            </a>
            <button
              type="button"
              className="-m-2.5 rounded-md p-2.5 text-gray-700"
              onClick={() => setMobileMenuOpen(false)}
            >
              <span className="sr-only">Close menu</span>
              <XMarkIcon className="h-6 w-6" aria-hidden="true" />
            </button>
          </div>
          <div className="mt-6 flow-root">
            <div className="-my-6 divide-y divide-gray-500/10">
              <div className="space-y-2 py-6">
              {navigation.map((item) => {
            // Sjekker om menyelementet er 'Admin' og om brukeren er admin
            if (item.name === 'Admin' && !userData.admin) {
              return null; // Returnerer null hvis brukeren ikke er admin, noe som hindrer at elementet vises
            }
            return (
              <a
                    key={item.name}
                    href={item.href}
                    className="-mx-3 flex block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-stone-900 hover:bg-[f36699]"
                  >
                   {item.name === 'Admin' ? <Cog6ToothIcon
                            className="h-6 w-6 pr-1 shrink-0 text-gray-400 group-hover:text-[f36699]"
                            aria-hidden="true"
                          /> : ""}
                    {item.name}
                  </a>

            );
          })}
              </div>
              <div className="py-6">
                <a
                  onClick={() => setIsModalOpen(true)}
                  className="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-stone-900 hover:bg-gray-50"
                >
                  Logg inn
                </a>
              </div>
            </div>
          </div>
        </Dialog.Panel>
      </Dialog>
    </header>
  )
}
