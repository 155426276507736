import React, { useState, useEffect } from 'react';
import { onSnapshot, collection, query, where } from "firebase/firestore";
import { db } from "../firebase";
import RecipeCard from "../components/profile/recipe/RecipeCard";
import RecipeDetail from "../components/profile/recipe/RecipeDetail";

interface Recipe {
  id: string;
  title: string;
  description: string;
  price: number;
  // Legg til andre felt basert på din Firestore-dokumentstruktur
}

export default function RecipePage() {
  const [selectedRecipe, setSelectedRecipe] = useState<Recipe | null>(null);
  const [isDetailOpen, setIsDetailOpen] = useState(false);
  const [recipes, setRecipes] = useState<Recipe[]>([]);
  const [selectedCategory, setSelectedCategory] = useState<string | null>(null);

  const handleCategorySelect = (category: string) => {
    setSelectedCategory(category);
  };

  useEffect(() => {
    const fetchRecipes = () => {
      let q;
      if (selectedCategory) {
        // Inkluderer filtrering basert på valgt kategori
        q = query(collection(db, "recipes"), where("isActive", "==", true), where("mainCategory", "==", selectedCategory));
      } else {
        // Henter alle aktive oppskrifter hvis ingen kategori er valgt
        q = query(collection(db, "recipes"), where("isActive", "==", true));
      }
  
      const unsubscribe = onSnapshot(q, (querySnapshot) => {
        const recipesData = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...(doc.data() as Omit<Recipe, 'id'>),
        }));
        setRecipes(recipesData);
      });
  
      return unsubscribe;
    };
  
    const unsubscribe = fetchRecipes();
    return () => unsubscribe();
  }, [selectedCategory]);

  const handleCardClick = (recipe: Recipe) => {
    setSelectedRecipe(recipe);
    setIsDetailOpen(true);
  };

  const handleCloseDetail = () => {
    setIsDetailOpen(false);
    setSelectedRecipe(null);
  };

  return (
    <>
      <div className="min-h-full">
        <div className="bg-[#465745] pb-32">
          <header className="py-10">
          <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
          <div className="flex justify-between">
          <h1 className="text-4xl font-light tracking-tight text-stone-300">Kategorier</h1>
    <h1 className="text-4xl font-light tracking-tight text-stone-300">Shop symønstre</h1>
          </div>
          {["Bukse", "Skjørt", "Genser", "Topper", "Kjole", "Jakke", "Accessories"].map((category) => (
  <button
    key={category}
    onClick={() => handleCategorySelect(category)}
    className={`mt-6 rounded-xl py-2 px-4 text-sm font-bold mr-2 ${
      selectedCategory === category ? "bg-[#455644] text-stone-50 hover:bg-[#465745]" : "bg-stone-50 text-[#455644] hover:bg-gray-100"
    }`}
  >
     {category}
  </button>
))}
        </div>
          </header>
        </div>

        <main className="-mt-32 flex">
          <div className="mx-auto max-w-7xl px-4 pb-12 sm:px-6 lg:px-8">
            <div className="rounded-lg bg-stone-50 px-5 py-6 shadow sm:px-6">
              <div className="grid grid-cols-1 gap-y-4 sm:grid-cols-3 sm:gap-x-4 sm:gap-y-10 lg:grid-cols-4 lg:gap-x-8">
                {recipes.map((recipe) => (
                  <RecipeCard key={recipe.id} recipe={recipe} onCardClick={handleCardClick} />
                ))}
              </div>
              {isDetailOpen && <RecipeDetail recipe={selectedRecipe} onClose={handleCloseDetail} />}
            </div>
          </div>
        </main>
      </div>
    </>
  );
}