import image1 from '../../images/close-up-woman-using-smartphone-scrolling-web-page.jpg'
import image2 from '../../images/close-up-hands-folding-vest.jpg'
import image3 from '../../images/side-view-woman-cutting-fabric.jpg'
import image4 from '../../images/tailoring-items-arrangement-still-life.jpg'
import image5 from '../../images/shoulder-view-tailor-woman-using-sewing-machine.jpg'


export default function ProductCard() {
    return (
        <>
        
       <div className="mx-auto max-w-7xl px-6 pb-16 sm:pb-32 pt-16 sm:pt-40 lg:px-8 lg:pt-16">
         <div className="mx-auto max-w-2xl gap-x-14 lg:mx-0 lg:flex lg:max-w-none lg:items-center">
           <div className="relative w-full max-w-xl lg:shrink-0 xl:max-w-2xl">
           <p className="text-base font-semibold leading-7 text-[#F36699]">Gjør deg klar for vår lansering - registrer deg i dag!</p>
             <h1 className="text-2xl font-bold tracking-tight text-stone-900 sm:text-4xl">
            
             Oppdag unike, bærekraftige design og bli en del av det voksende syfellesskapet.
             </h1>
             <p className="mt-6 text-lg leading-8 text-gray-600 sm:max-w-md lg:max-w-none">
             Støtt småskala designermerker med unikt og etisk produksjonsfokus på vår markedsplass.

             </p>
             <div className="mt-10 flex items-center gap-x-8">
               <a
                 href="/listings"
                 className="rounded-md bg-[#F36699] px-8 py-4 text-md font-semibold text-white shadow-sm hover:bg-[#465745] focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
               >
                 Shop klær <span aria-hidden="true">→</span>
               </a>
               <a href="/recipes" className="rounded-md bg-[#F36699] px-8 py-4 text-md font-semibold text-white shadow-sm hover:bg-[#465745] focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
                 Shop symønstre <span aria-hidden="true">→</span>
               </a>
             </div>
           </div>
           <div className="hidden lg:flex  mt-14 flex justify-end gap-8 sm:-mt-44 sm:justify-start sm:pl-20 lg:mt-0 lg:pl-0">
             <div className="ml-auto w-40 flex-none space-y-8 pt-32 sm:ml-0 sm:pt-80 lg:order-last lg:pt-36 xl:order-none xl:pt-80">
               <div className="relative">
                 <img
                   src={image1}
                   alt=""
                   className="aspect-[2/3] w-full rounded-xl bg-gray-900/5 object-cover shadow-lg"
                 />
                 <div className="pointer-events-none absolute inset-0 rounded-xl ring-1 ring-inset ring-gray-900/10" />
               </div>
             </div>
             <div className="mr-auto w-40 flex-none space-y-8 sm:mr-0 sm:pt-52 lg:pt-36">
               <div className="relative">
                 <img
                   src={image2}
                   alt=""
                   className="aspect-[2/3] w-full rounded-xl bg-gray-900/5 object-cover shadow-lg"
                 />
                 <div className="pointer-events-none absolute inset-0 rounded-xl ring-1 ring-inset ring-gray-900/10" />
               </div>
               <div className="relative">
                 <img
                   src={image3}
                   alt=""
                   className="aspect-[2/3] w-full rounded-xl bg-gray-900/5 object-cover shadow-lg"
                 />
                 <div className="pointer-events-none absolute inset-0 rounded-xl ring-1 ring-inset ring-gray-900/10" />
               </div>
             </div>
             <div className="w-40 flex-none space-y-8 pt-32 sm:pt-0">
               <div className="relative">
                 <img
                   src={image4}
                   alt=""
                   className="aspect-[2/3] w-full rounded-xl bg-gray-900/5 object-cover shadow-lg"
                 />
                 <div className="pointer-events-none absolute inset-0 rounded-xl ring-1 ring-inset ring-gray-900/10" />
               </div>
               <div className="relative">
                 <img
                   src={image5}
                   alt=""
                   className="aspect-[2/3] w-full rounded-xl bg-gray-900/5 object-cover shadow-lg"
                 />
                 <div className="pointer-events-none absolute inset-0 rounded-xl ring-1 ring-inset ring-gray-900/10" />
               </div>
             </div>
           </div>
         </div>
       </div>
</>
    )}