import ProductCard from "../components/shop/ProductCard";
import SowSection from "../components/home/SowSection";
import CreatorSection from "../components/home/CreatorSection";
import HeaderSection from "../components/home/HeaderSection";
import ListingSection from "../components/home/ListingSection";
import CourseSection from "../components/home/CourseSection";
import CreatorCard from "../components/creator/CreatorCard";
import { useData } from '../DataContext';
import designIcon from "../images/icons/UseLess Icon Circle Group - Beige & Brown.png"



export default function Home() {
    const { userData, setUserData, userId, setUserId  } = useData();
    console.log(userData)

    return (
        <div className="overflow-hidden">
           
        <HeaderSection></HeaderSection>

        <CourseSection></CourseSection>

        
        <ListingSection></ListingSection>
       
        <SowSection></SowSection>
        
        <CreatorSection></CreatorSection>
        </div>
    )
  }
  