
import { doc, onSnapshot, DocumentData, query, collection, where } from 'firebase/firestore';
import { db } from "../firebase";
import RecipeCard from "../components/profile/recipe/RecipeCard";
import RecipeDetail from "../components/profile/recipe/RecipeDetail";
import { Fragment, useState, useEffect } from 'react'
import { Dialog, Menu, Transition } from '@headlessui/react'
import { XMarkIcon } from '@heroicons/react/24/outline'
import { EllipsisVerticalIcon } from '@heroicons/react/20/solid'
import ListingCard from "../components/profile/listing/ListingCard";
import ListingDetail from "../components/profile/listing/ListingDetail";
import { useData } from '../DataContext';

interface Recipe {
  id: string;
  title: string;
  description: string;
  price: number;
  // Legg til andre felt basert på din Firestore-dokumentstruktur
}

function classNames(...classes: any) {
    return classes.filter(Boolean).join(' ')
  }
  
  interface Listing {
    id: string;
    title: string;
    description: string;
    price: number;
    // Legg til andre felt basert på din Firestore-dokumentstruktur
  }


interface CreatorApplication {
    age: string;
    creatorBio: string;
    imageURL: string;
    location: string;
    otherComments: string;
    reason: string;
    recipe: boolean;
    socialMedia: string;
    sow: boolean;
    themeColor: string;
  }
  
  interface Creator {
    id: string;
    name: string;
    email: string;
    creatorApplication: CreatorApplication; // Inkluderer CreatorApplication som en del av Creator-typen
  }
  
  

export default function CreatorShopPage() {
    const urlParts = window.location.href.split("/");
    const creatorId = urlParts[urlParts.length - 1];


    const [creator, setCreator] = useState<Creator | null>(null);
    const [productView, setProductView] = useState("listings");

    const [isDetailOpen, setIsDetailOpen] = useState(false);
  const [selectedListing, setSelectedListing] = useState<Listing | null>(null);
  const [listings, setListings] = useState<Listing[]>([]);
  const { userData } = useData();
  const [selectedRecipe, setSelectedRecipe] = useState<Recipe | null>(null);
  const [recipes, setRecipes] = useState<Recipe[]>([]);

  const firebaseConsoleBaseUrl = "https://console.firebase.google.com/u/0/project/useless-411f7/firestore/data/~2Fusers~2F";

  useEffect(() => {
    const unsubscribe = onSnapshot(doc(db, "users", creatorId), (docSnapshot) => {
      if (docSnapshot.exists()) {
        const data = docSnapshot.data();
        const creatorData: Creator = {
          id: docSnapshot.id,
          name: data.name,
          email: data.email,
          creatorApplication: {
            age: data.creatorApplication.age,
            creatorBio: data.creatorApplication.creatorBio,
            imageURL: data.creatorApplication.imageURL,
            location: data.creatorApplication.location,
            otherComments: data.creatorApplication.otherComments,
            reason: data.creatorApplication.reason,
            recipe: data.creatorApplication.recipe,
            socialMedia: data.creatorApplication.socialMedia,
            sow: data.creatorApplication.sow,
            themeColor: data.creatorApplication.themeColor,
          },
        };

        console.log(creatorData)
        setCreator(creatorData);
      } else {
        console.log("No such creator!");
        setCreator(null);
      }
    });

    return () => unsubscribe();
  }, [creatorId]);


  const handleEditClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation(); // Stopper event-bobling
    window.open(`${firebaseConsoleBaseUrl}${creator?.id}`, '_blank'); // Åpner Firebase URL i ny fane
  };


  useEffect(() => {
    console.log("kjører")
    const fetchListings = () => {
      const q = query(collection(db, "listings"), where("creatorId", "==", creatorId));
      const unsubscribe = onSnapshot(q, (querySnapshot: any) => {
        const listingsData: Listing[] = [];
        querySnapshot.forEach((doc: any) => {
          listingsData.push({
            id: doc.id,
            ...(doc.data() as Omit<Listing, 'id'>)
          });
        });
        setListings(listingsData);
      });

      return unsubscribe;
    };

    const unsubscribe = fetchListings();
    return () => unsubscribe();
  }, [creator]);

  useEffect(() => {
    const fetchRecipes = () => {
      const q = query(collection(db, "recipes"), where("creatorId", "==", creatorId));
      const unsubscribe = onSnapshot(q, (querySnapshot: any) => {
        const recipesData: Recipe[] = [];
        querySnapshot.forEach((doc: any) => {
          recipesData.push({
            id: doc.id,
            ...(doc.data() as Omit<Listing, 'id'>)
          });
        });
        setRecipes(recipesData);
      });

      return unsubscribe;
    };

    const unsubscribe = fetchRecipes();
    return () => unsubscribe();
  }, [productView]);


  const handleCardClick = (listing: Listing) => {
    setSelectedListing(listing);
    setIsDetailOpen(true);
  };

  const handleCloseDetail = () => {
    setIsDetailOpen(false);
    setSelectedListing(null);
  };


  return (
    <>
      

                            <div className="divide-y divide-gray-200">
                      <div className="pb-6">
                      <div style={{ backgroundColor: creator?.creatorApplication.themeColor || '#F1DDC2' }} className="drop-zone sm:h-20 lg:h-28" />
                        <div className="-mt-12 flow-root px-4 sm:-mt-8 sm:flex sm:items-end sm:px-6 lg:-mt-16">
                          <div>
                            <div className="-m-1 flex">
                              <div className="inline-flex overflow-hidden rounded-lg border-4 border-white">
                                <img
                                  className="h-24 w-24 object-cover flex-shrink-0 sm:h-40 sm:w-40 lg:h-48 lg:w-48"
                                  src={creator?.creatorApplication.imageURL}
                                  alt=""
                                />
                              </div>
                            </div>
                          </div>
                          <div className="mt-6 sm:ml-6 sm:flex-1">
                            <div>
                              <div className="flex items-center">
                                <h3 className="text-xl font-bold text-stone-900 sm:text-2xl">{creator?.name}</h3>
                                            {userData.admin && (
                            <button
                              onClick={handleEditClick}
                              className="ml-2 inline-flex z-10 items-center rounded-full bg-blue-100 px-1.5 py-0.5 text-xs font-medium text-blue-700 hover:bg-blue-50"
                            >
                              Rediger
                            </button>
                          )}
                              </div>
                              <p className="text-sm text-gray-500">{creator?.creatorApplication.location}</p>
                            </div>
                            <div className="mt-5 flex flex-wrap space-y-3 sm:space-x-3 sm:space-y-0">
                              <button
                                type="button"
                                onClick={()=> {productView == "listings" ? setProductView("recipes"): setProductView("listings")}}
                                style={{ backgroundColor: creator?.creatorApplication.themeColor || '#F1DDC2' }}
                                className="inline-flex w-full flex-shrink-0 items-center justify-center rounded-md bg-[#8D4304] px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-[#465745] focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-[#8D4304] sm:flex-1"
                              >
                                {productView == "listings" ? "Se symønstre": "Se klær"}
                              </button>
                              <a
                                href={`mailto:${creator?.email}`}
                                className="inline-flex w-full flex-1 items-center justify-center rounded-md bg-stone-50 px-3 py-2 text-sm font-semibold text-stone-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                              >
                                Send melding
                              </a>
                              <div className="ml-3 inline-flex sm:ml-0">
                                <Menu as="div" className="relative inline-block text-left">
                                  <Menu.Button className="relative inline-flex items-center rounded-md bg-stone-50 p-2 text-gray-400 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
                                    <span className="absolute -inset-1" />
                                    <span className="sr-only">Åpne flervalgsmeny</span>
                                    <EllipsisVerticalIcon className="h-5 w-5" aria-hidden="true" />
                                  </Menu.Button>
                                  <Transition
                                    as={Fragment}
                                    enter="transition ease-out duration-100"
                                    enterFrom="transform opacity-0 scale-95"
                                    enterTo="transform opacity-100 scale-100"
                                    leave="transition ease-in duration-75"
                                    leaveFrom="transform opacity-100 scale-100"
                                    leaveTo="transform opacity-0 scale-95"
                                  >
                                    <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-stone-50 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                      <div className="py-1">
                                        <Menu.Item>
                                          {({ active }) => (
                                            <a
                                              href="#"
                                              className={classNames(
                                                active ? 'bg-gray-100 text-stone-900' : 'text-gray-700',
                                                'block px-4 py-2 text-sm'
                                              )}
                                            >
                                              Skriv en anmeldelse (ikke aktiv)
                                            </a>
                                          )}
                                        </Menu.Item>
                                        <Menu.Item>
                                          {({ active }) => (
                                            <a
                                              href="#"
                                              className={classNames(
                                                active ? 'bg-gray-100 text-stone-900' : 'text-gray-700',
                                                'block px-4 py-2 text-sm'
                                              )}
                                            >
                                              Rapporter (ikke aktiv)
                                            </a>
                                          )}
                                        </Menu.Item>
                                      </div>
                                    </Menu.Items>

                                  </Transition>
                                </Menu>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="px-4 py-5 sm:px-0 sm:py-0">
                        <dl className="space-y-8 sm:space-y-0 sm:divide-y sm:divide-gray-200">
                          <div className="sm:flex sm:px-6 sm:py-5">
                            <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0 lg:w-48">Bio</dt>
                            <dd className="mt-1 text-sm text-stone-900 sm:col-span-2 sm:ml-6 sm:mt-0">
                              <p>
                                {creator?.creatorApplication.creatorBio}
                              </p>
                            </dd>
                          </div>
                          { /*
                          <div className="sm:flex sm:px-6 sm:py-5">
                            <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0 lg:w-48">
                              Sosiale medier
                            </dt>
                            <dd className="mt-1 text-sm text-stone-900 sm:col-span-2 sm:ml-6 sm:mt-0">
                              {creator?.creatorApplication.socialMedia}
                            </dd>
                          </div>
                                              */ }
                        </dl>
                      </div>
                    </div>
                    <div className="m-10 grid grid-cols-1 gap-y-4 sm:grid-cols-3 sm:gap-x-4 sm:gap-y-10 lg:grid-cols-4 lg:gap-x-8">
                {productView == "listings" ? 
                <>
                {listings.map((listing) => (
                  <ListingCard key={listing.id} listing={listing} onCardClick={handleCardClick} />
                  
                ))}
                </>:
                <>
                {recipes.map((recipe) => (
                  <RecipeCard key={recipe.id} recipe={recipe} onCardClick={handleCardClick} />
                  
                ))}
                </>
                }
              </div>
               
              {isDetailOpen && <ListingDetail listing={selectedListing} onClose={handleCloseDetail} />}
                
    </>
  );
}