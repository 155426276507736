import { Fragment } from 'react';
import { Disclosure } from '@headlessui/react';
import { ChevronUpIcon } from '@heroicons/react/20/solid';

export default function SectionsList({ sections, onVideoSelect }: any) {
    return (
      <div className="w-1/2 pt-10">
        {sections.map((section: any) => (
          <Disclosure as="div" key={section.name} className="mt-2">
            {({ open }) => (
              <>
                <Disclosure.Button className="flex w-full justify-between rounded-lg bg-[#f36699] px-4 py-2 text-left text-sm font-medium text-white hover:bg-pink-700 focus:outline-none focus-visible:ring focus-visible:ring-opacity-75">
                  <span>{section.name}</span>
                  <ChevronUpIcon className={`${open ? 'transform rotate-180' : ''} h-5 w-5 text-white`} />
                </Disclosure.Button>
                <Disclosure.Panel className="px-4 pt-4 pb-2 text-sm text-gray-500">
                  <ul>
                    {section.videos.map((video: any) => (
                      <li key={video.name} className="py-1">
                        <button onClick={() => onVideoSelect(section, video)} className="hover:underline">
                          {video.name}
                        </button>
                      </li>
                    ))}
                  </ul>
                </Disclosure.Panel>
              </>
            )}
          </Disclosure>
        ))}
      </div>
    );
  }
