import React, { useState, useEffect } from 'react';
import { onSnapshot, collection, query, where } from "firebase/firestore";
import { db } from "../firebase";
import ListingCard from "../components/profile/listing/ListingCard";
import ListingDetail from "../components/profile/listing/ListingDetail";

interface Listing {
  id: string;
  title: string;
  description: string;
  price: number;
  // Legg til andre felt basert på din Firestore-dokumentstruktur
}

export default function ListingPage() {
  const [selectedListing, setSelectedListing] = useState<Listing | null>(null);
  const [isDetailOpen, setIsDetailOpen] = useState(false);
  const [listings, setListings] = useState<Listing[]>([]);
  const [selectedCategory, setSelectedCategory] = useState<string | null>(null);

  const handleCategorySelect = (category: string) => {
    setSelectedCategory(category);
  };

  useEffect(() => {
    const fetchListings = () => {
      let q = query(collection(db, "listings"), where("isActive", "==", true));
  
      if (selectedCategory) {
        q = query(q, where("mainCategory", "==", selectedCategory));
      }
  
      const unsubscribe = onSnapshot(q, (querySnapshot) => {
        const listingsData: Listing[] = [];
        querySnapshot.forEach((doc) => {
          listingsData.push({
            id: doc.id,
            ...(doc.data() as Omit<Listing, 'id'>)
          });
        });
        setListings(listingsData);
      });
  
      return unsubscribe;
    };
  
    const unsubscribe = fetchListings();
    return () => unsubscribe();
  }, [selectedCategory]);

  const handleCardClick = (listing: Listing) => {
    setSelectedListing(listing);
    setIsDetailOpen(true);
  };

  const handleCloseDetail = () => {
    setIsDetailOpen(false);
    setSelectedListing(null);
  };

  return (
    <>
      <div className="min-h-full">
        <div className="bg-[#465745] pb-32">
          <header className="py-10">
          <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
  <div className="flex justify-between mb-4">
    <h1 className="text-4xl font-light tracking-tight text-stone-300">Kategorier</h1>
    <h1 className="text-4xl font-light tracking-tight text-stone-300">Shop klær</h1>
  </div>
  {["Bukse", "Skjørt", "Genser", "Topper", "Kjole", "Jakke", "Accessories"].map((category) => (
  <button
    key={category}
    onClick={() => handleCategorySelect(category)}
    className={`mt-6 rounded-xl py-2 px-4 text-sm font-bold mr-2 ${
      selectedCategory === category ? "bg-[#455644] text-stone-50 hover:bg-[#465745]" : "bg-stone-50 text-[#455644] hover:bg-gray-100"
    }`}
  >
     {category}
  </button>
))}
</div>
          </header>
        </div>

        <main className="-mt-32 flex">
          <div className="mx-auto max-w-7xl px-4 pb-12 sm:px-6 lg:px-8">
            <div className="rounded-lg bg-stone-50 px-5 py-6 shadow sm:px-6">
              <div className="grid grid-cols-1 gap-y-4 sm:grid-cols-3 sm:gap-x-4 sm:gap-y-10 lg:grid-cols-4 lg:gap-x-8">
                {listings.map((listing) => (
                  <ListingCard key={listing.id} listing={listing} onCardClick={handleCardClick} />
                ))}
              </div>
              {isDetailOpen && <ListingDetail listing={selectedListing} onClose={handleCloseDetail} />}
            </div>
          </div>
        </main>
      </div>
    </>
  );
}