
import { Fragment } from 'react'
import { Tab } from '@headlessui/react'
import React, { useState, useEffect } from 'react';
import { doc, getDoc } from 'firebase/firestore';
import { db } from "../firebase";
import { getFunctions, httpsCallable } from "firebase/functions";
import { useData } from '../DataContext';
import SectionsList from '../components/course/CourseList';

const license = {
  href: '#',
  summary:
    'Til personlig og profesjonell bruk. Du kan ikke videreselge eller redistribuere disse ikonene i deres opprinnelige eller endrede tilstand.',
    content: `
    <h4>Oversikt</h4>
    
    <p>For personlig og profesjonell bruk. Du kan ikke videreselge eller redistribuere disse ikonene i deres opprinnelige eller modifiserte tilstand.</p>
    
    <ul role="list">
    <li>Du har lov til å bruke ikonene i ubegrensede prosjekter.</li>
    <li>Tilskrivning er ikke nødvendig for å bruke ikonene.</li>
    </ul>
    
    <h4>Hva du kan gjøre med det</h4>
    
    <ul role="list">
    <li>Bruk dem fritt i ditt personlige og profesjonelle arbeid.</li>
    <li>Gjør dem til dine egne. Endre fargene for å passe ditt prosjekt eller merkevare.</li>
    </ul>
    
    <h4>Hva du ikke kan gjøre med det</h4>
    
    <ul role="list">
    <li>Ikke vær grådig. Det er forbudt å selge eller distribuere disse ikonene i deres opprinnelige eller modifiserte tilstand.</li>
    <li>Ikke vær ond. Disse ikonene kan ikke brukes på nettsteder eller applikasjoner som fremmer ulovlige eller umoralske overbevisninger eller aktiviteter.</li>
    </ul>
  `,

}

function classNames(...classes: any) {
  return classes.filter(Boolean).join(' ')
}

interface Video {
    name: string;
    videoAlt?: string; // Antar at dette feltet er valgfritt
    videoDesc: string;
    videoSrc: string;
}

interface Section {
    desc: string;
    name: string;
    videos: Video[];
}

interface FAQ {
    question: string;
    answer: string;
}

interface FeaturedReview {
    id: number;
    author: string;
    avatarSrc: string;
    content: string;
    date: string;
    dateTime: string; // Dette feltet ser ut til å være duplisert med 'date', kanskje du mente 'datetime'?
    rating: number;
}

interface Review {
    average: number;
    featured: FeaturedReview[];
}

interface Course {
    name: string;
    version: string;
    courseDesc: string;
    highlights: string[];
    previewImage: string;
    previewVideo: string;
    price: number;
    faqs: FAQ[];
    reviews: Review;
    sections: Section[];
}

interface StripeCheckoutSessionResponse {
    data: {
      url: string;
    };
  }


export default function CoursePage() {

    const [course, setCourse] = useState<Course | null>(null);
    const [showVideo, setShowVideo] = useState(false);
    const [playingVideo, setPlayingVideo] = useState("");
    const { userData, userId } = useData();
    const urlParts = window.location.href.split("/");
    const courseId = urlParts[urlParts.length - 1];

// Utvider tilstandsvariabelen til også å holde kursinformasjon
const [selectedContent, setSelectedContent] = useState({
    sectionName: "",
    sectionDesc: "",
    sectionFeatures: [],
    videoName: "",
    videoDesc: "",
    videoSrc: course?.previewVideo
  });
  
  const handleVideoSelect = (section: any, video: any) => {
    setSelectedContent({
      sectionName: section.name,
      sectionDesc: section.desc,
      sectionFeatures: section.features,
      videoName: video.name,
      videoDesc: video.videoDesc,
      videoSrc: video.videoSrc
    });
  };


    useEffect(() => {
        async function fetchCourse() {
            const docRef = doc(db, "courses", courseId); 
            const docSnap = await getDoc(docRef);

            if (docSnap.exists()) {
                setCourse(docSnap.data() as Course);
                setPlayingVideo(docSnap.data().previewVideo)
            } else {
                console.log("No such document!");
            }
        }

        fetchCourse();
    }, []);

    const functions = getFunctions(db.app, 'europe-west1'); // Pass på å angi riktig region

const handleCreateCheckoutSession = async () => {
  if (!course) return;

  const createStripeCheckoutCourse = httpsCallable(functions, 'createStripeCheckoutCourse');
  
  try {
    const sessionResponse = await createStripeCheckoutCourse({ course: course, email: userData.email, userId: userId }) as StripeCheckoutSessionResponse;
    // Omdiriger brukeren til Stripe Checkout
    const { url } = sessionResponse.data;
    window.location.href = url;
  } catch (error) {
    console.error('Kunne ikke opprette Stripe Checkout-session', error);
  }
};
   
    if (!course) return <div className="ml-40 mt-20" >Laster kurs...</div>;

  return (
    <div className="bg-white">
      <div className="mx-auto px-4 py-16 sm:px-6 sm:py-24 lg:max-w-7xl lg:px-8">
        {/* Product */}
        <div className="lg:grid lg:grid-cols-7 lg:grid-rows-1 lg:gap-x-8 lg:gap-y-10 xl:gap-x-16">
          {/* Product image */}
          <div className="lg:col-span-4 lg:row-end-1">
            <div className="aspect-h-3 aspect-w-4 overflow-hidden rounded-lg bg-gray-100">

            {showVideo || selectedContent.videoSrc ? (
                <video autoPlay src={playingVideo} className="object-cover object-center" controls>Your browser does not support the video tag.</video>
              ) : (
                <img src={course.previewImage} alt="Course preview" className="object-cover object-center" />
              )}
            </div>
            
            <p className="mt-6 text-gray-500">{selectedContent? selectedContent.videoDesc:""}</p>
          </div>

          {/* Product details */}
          <div className="mx-auto mt-14 max-w-2xl sm:mt-16 lg:col-span-3 lg:row-span-2 lg:row-end-2 lg:mt-0 lg:max-w-none">
            <div className="flex flex-col-reverse">
              <div className="mt-4">
                <h1 className="text-2xl font-bold tracking-tight text-gray-900 sm:text-3xl">{selectedContent.videoName?selectedContent.videoName:course.name}</h1>

                <h2 id="information-heading" className="sr-only">
                  Product information
                </h2>
                <p className="mt-2 text-sm text-gray-500">
                  Levert av {course.version}
               
                </p>
              </div>

            </div>

            <p className="mt-6 text-gray-500">{selectedContent.sectionDesc?selectedContent.sectionDesc:course.courseDesc}</p>

            
            {/* Sjekk om brukeren allerede har kjøpt kurset */}
            {userData.purchasedCourses && userData.purchasedCourses.find((pc: any) => pc.courseId === courseId) ? (
                               <SectionsList sections={course.sections} onVideoSelect={handleVideoSelect} />

            ) : (
                <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-4 sm:grid-cols-2">
                <button
                type="button"
                className="flex w-full items-center justify-center rounded-md border border-transparent bg-[#f36699] px-8 py-3 text-base font-medium text-white hover:bg-pink-700 focus:outline-none focus:ring-2 focus:ring-pink-500 focus:ring-offset-2 focus:ring-offset-gray-50"
                onClick={handleCreateCheckoutSession}
                >
                Betal {course.price} kr
                </button>
           

            {/* Forhåndsvis-knappen vises uavhengig */}
            {!showVideo && (
                <button
                type="button"
                className="flex w-full items-center justify-center rounded-md border border-transparent bg-pink-50 px-8 py-3 text-base font-medium text-[#f36699] hover:bg-pink-100 focus:outline-none focus:ring-2 focus:ring-[#f36699] focus:ring-offset-2 focus:ring-offset-gray-50"
                onClick={() => setShowVideo(true)}
                >
                Forhåndsvis
                </button>
                 
            )}
         </div>
          )}

            <div className="mt-10 border-t border-gray-200 pt-10">
              <h3 className="text-sm font-medium text-gray-900">{selectedContent.sectionName?selectedContent.sectionName:"Highlights"}</h3>
              <div className="prose prose-sm mt-4 text-gray-500">
                <ul role="list">
                    {selectedContent.sectionFeatures.length > 0?
                    selectedContent.sectionFeatures.map((highlight) => (
                        <li key={highlight}>{highlight}</li>
                      ))
                      :
                      course.highlights.map((highlight) => (
                        <li key={highlight}>{highlight}</li>
                      ))
                }
                </ul>
              </div>
            </div>

            <div className="mt-10 border-t border-gray-200 pt-10">
              <h3 className="text-sm font-medium text-gray-900">Lisens</h3>
              <p className="mt-4 text-sm text-gray-500">
                {license.summary}{' '}
                <a href={license.href} className="font-medium text-[#f36699] hover:text-pink-500">
                  Les hele lisensen
                </a>
              </p>
            </div>
          </div>

          <div className="mx-auto mt-16 w-full max-w-2xl lg:col-span-4 lg:mt-0 lg:max-w-none">
            <Tab.Group as="div">
              <div className="border-b border-gray-200">
                
                <Tab.List className="-mb-px flex space-x-8">
                  <Tab
                    className={({ selected }) =>
                      classNames(
                        selected
                          ? 'border-[#f36699] text-[#f36699]'
                          : 'border-transparent text-gray-700 hover:border-gray-300 hover:text-gray-800',
                        'whitespace-nowrap border-b-2 py-6 text-sm font-medium'
                      )
                    }
                  >
                    FAQ
                  </Tab>
               
                  <Tab
                    className={({ selected }) =>
                      classNames(
                        selected
                          ? 'border-[#f36699] text-[#f36699]'
                          : 'border-transparent text-gray-700 hover:border-gray-300 hover:text-gray-800',
                        'whitespace-nowrap border-b-2 py-6 text-sm font-medium'
                      )
                    }
                  >
                    Lisens
                  </Tab>
                </Tab.List>
              </div>
              <Tab.Panels as={Fragment}>
            

                <Tab.Panel className="text-sm text-gray-500">
                  <h3 className="sr-only">Frequently Asked Questions</h3>

                  <dl>
                    {course.faqs.map((faq) => (
                      <Fragment key={faq.question}>
                        <dt className="mt-10 font-medium text-gray-900">{faq.question}</dt>
                        <dd className="prose prose-sm mt-2 max-w-none text-gray-500">
                          <p>{faq.answer}</p>
                        </dd>
                      </Fragment>
                    ))}
                  </dl>
                </Tab.Panel>

                <Tab.Panel className="pt-10">
                  <h3 className="sr-only">Lisens</h3>

                  <div
                    className="prose prose-sm max-w-none text-gray-500"
                    dangerouslySetInnerHTML={{ __html: license.content }}
                  />
                </Tab.Panel>
              </Tab.Panels>
            </Tab.Group>
          </div>
        </div>
      </div>
    </div>
  )
}
