import React, { useState, useEffect } from 'react';
import RecipeCard from "./RecipeCard"
import RecipeDetail from "./RecipeDetail"
import AddRecipe from "./AddRecipe"
import { PlusIcon } from '@heroicons/react/20/solid'
import { useData } from '../../../DataContext';
import { doc, updateDoc } from "firebase/firestore";
import { db } from "../../../firebase";

export default function MyRecipes() {
    const [selectedRecipe, setSelectedRecipe] = useState(null);
    const [isDetailOpen, setIsDetailOpen] = useState(false);
    const [isAddOpen, setIsAddOpen] = useState(false);
    const { recipes  } = useData();

    const handleCardClick = (recipe: any) => {
        setSelectedRecipe(recipe);
        setIsDetailOpen(true);
    };

    const handleCloseDetail = () => {
        setIsDetailOpen(false);
        setSelectedRecipe(null);
    };

    const handleAddClick = () => {
      setIsAddOpen(true);
    };

  const handleCloseAdd = () => {
    setIsAddOpen(false);
  };

  const onTogglePublish = async (recipe: any) => {
    const recipeDocRef = doc(db, "recipes", recipe.id);
  
    try {
      await updateDoc(recipeDocRef, {
        isActive: !recipe.isActive
      });
      console.log("Recipe publiseringsstatus oppdatert");
      // Du kan også oppdatere tilstanden her for å reflektere endringen umiddelbart i UI
    } catch (error) {
      console.error("Kunne ikke oppdatere recipe publiseringsstatus", error);
    }
  };

    return (
        <>


            <main className="relative isolate min-h-full">
            <div className="bg-stone-50 py-6 sm:py-8">
      <div className="mx-auto max-w-7xl text-center sm:text-left">
        <div className="mx-auto max-w-2xl lg:mx-0">
          <p className="text-base font-semibold leading-7 text-[#F36699]">Creator</p>
          <div className="flex">
          <h2 className="mt-2 text-4xl font-bold tracking-tight text-[#8D4304] sm:text-6xl">Mine oppskrifter</h2>
      </div>
      <button
        type="button"
        className="mt-4 inline-flex items-center gap-x-1.5 rounded-md bg-[#F36699] px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-[#465745] focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-[#465745]"
        onClick={handleAddClick}
      >
        <PlusIcon className="-ml-0.5 h-5 w-5 " aria-hidden="true" />
        Legg til oppskrift
      </button>
          <p className="mt-6 text-lg leading-8 text-gray-600">
          Her kan du legge til, se, redigere og slette dine sy-oppskrifter som creator.
          </p>
        </div>
      </div>
    </div>

            <div className="grid grid-cols-1 gap-y-4 sm:grid-cols-2 sm:gap-x-3 sm:gap-y-10 lg:grid-cols-3 lg:gap-x-8">
            {recipes.map((recipe: any) => (
              <div>
              <button
              onClick={() => onTogglePublish(recipe)}
              className="rounded-lg bg-pink-100 hover:bg-pink-50 px-2 my-1">
              {recipe.isActive ? "Deaktiver" : "Aktiver"}
            </button>
          <RecipeCard key={recipe.id} recipe={recipe} onCardClick={handleCardClick} />
          </div>
        ))}
                </div>
            </main>
            {isDetailOpen && <RecipeDetail recipe={selectedRecipe} onClose={handleCloseDetail} />}
            {isAddOpen && <AddRecipe onClose={()=>handleCloseAdd()} />}
        </>
    );
}
  