import { useState } from 'react'
import { Dialog } from '@headlessui/react'
import { Bars3Icon, XMarkIcon, BuildingOffice2Icon, EnvelopeIcon, PhoneIcon } from '@heroicons/react/24/outline'
import inspoImage from '../images/friends-posing-sun-outdoor-field.jpg'
import aneo from '../images/Aneo_Bidraget_upayoff-removebg-preview.png'
import discovery from '../images/Vannmerke_discovery.png'
import brother from '../images/brother-logo.svg'
import AM6 from '../images/6AM-placeholder-logo.png'




const stats = [
  { label: 'tekstiler kaster hver nordmann årlig.', value: '23,5 kg' },
  { label: 'av alle klesmerker bryter grunnleggende menneskerettigheter.', value: '93%' },
  { label: 'kreves i snitt til å lage et par med jeans – det tilsvarer mengden vann en gjennomsnittlig person drikker over en periode på syv år.', value: '7500 liter vann' },
]
const values = [
  {
    name: 'Ansvarlighet.',
    description:
      'Vi ønsker å ta ansvar der det er mulig. I en moteindustri preget av overforbruk og raske trender, ønsker vi å bidra til forbedring der vi kan.',
    img: "https://firebasestorage.googleapis.com/v0/b/useless-411f7.appspot.com/o/Responsibility%20(1).png?alt=media&token=34490a34-94f3-4fd8-86b2-8872b29e9fd5"
  },
  {
    name: 'Positiv Innvirkning.',
    description:
      'Vi ønsker å ha en positiv innvirkning på miljøet, mennesker og holdninger gjennom alle våre handlinger.',
      img: "https://firebasestorage.googleapis.com/v0/b/useless-411f7.appspot.com/o/Sincerity%20(1).png?alt=media&token=bb5907b0-3596-4e41-8dc3-4c30f3d33f86"
  },
  {
    name: 'Kreativitet.',
    description:
      'Vi vil fremme kreativitet, unik stil og mangfold som vi ser i samfunnet rundt oss. Vi ønsker å bryte ut av fastsatte normer og åpne opp for at kreativitet, endring og lønnsomhet kan gå hånd i hånd.',
      img: "https://firebasestorage.googleapis.com/v0/b/useless-411f7.appspot.com/o/nature_1.png?alt=media&token=4f2f5644-62bb-4af1-bfa4-ffc73eeb72e9"
  }
]
const team = [
  {
    name: 'Emma Skattum',
    role: 'Co-Founder / CEO',
    email: "emma.skattum@uselesshq.com",
    linkedIn: "https://linkedin.com/in/emma-skattum-202238198",
    imageUrl:
      'https://firebasestorage.googleapis.com/v0/b/useless-411f7.appspot.com/o/3%20(1).jpg?alt=media&token=fdaf4156-68d4-4d6b-be03-43a69550a0f2',
  },  {
    name: 'Solveig Grimstad',
    role: 'Co-Founder / CTO',
    email: "solveig.grimstad@uselsshq.com",
    linkedIn: "https://linkedin.com/in/solveig-bergan-grimstad-9351331b8",
    imageUrl:
      'https://firebasestorage.googleapis.com/v0/b/useless-411f7.appspot.com/o/1%20(1).jpg?alt=media&token=c880f603-d7ce-474f-878e-e2017de1d546',
  },
  {
    name: 'Johanna Foote',
    role: 'Creative Director',
    imageUrl:
      'https://firebasestorage.googleapis.com/v0/b/useless-411f7.appspot.com/o/2%20(1).jpg?alt=media&token=6ced7dca-0635-4931-b678-cc4abbdc8405',
  },
  // More people...
]

export default function AboutPage() {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false)

  return (
    <div className="bg-stone-50 mt-28">

      <main className="isolate">

        {/* Team section */}
        <div className="mx-auto  max-w-7xl px-6 lg:px-8 grid grid-cols-1 md:grid-cols-2">
          <div className="mx-auto max-w-2xl lg:mx-0">
            <h2 className="text-3xl font-bold tracking-tight text-stone-900 sm:text-4xl">Hvem er Useless</h2>
            <p className="mt-10 text-xl leading-8 text-gray-600">
                Vi fremmer endring gjennom kreativitet.
                </p>
            <p className="mt-6 mr-10 text-lg leading-8 text-gray-600">
            Gjennom å styrke designere til å skape unike merkevarer med fokus på bærekraft, fornyer vi moteindustrien ved å koble personlig og unik stil med miljøbevissthet.
            </p>
          </div>
          <div>
          <ul
            role="list"
            className="mx-auto grid grid-cols-3 mt-20 max-w-2xl gap-x-8 gap-y-16 text-center  lg:mx-0 lg:max-w-none"
          >
            {team.map((person) => (
              <li key={person.name}>
                <img className="ml-10 md:ml-2 xl:ml-10 h-40 w-auto rounded-xl" src={person.imageUrl} alt="" />
                <h3 className="mt-6 text-base font-semibold leading-7 tracking-tight text-stone-900">{person.name}</h3>
                <p className="text-sm leading-6 text-gray-600">{person.role}</p>
                <p className="text-xs leading-6 text-gray-600">{person.email}</p>
                {person.linkedIn?<a href={person.linkedIn} className="text-gray-400 hover:text-[#465745]">
                <img src="https://cdn.icon-icons.com/icons2/2428/PNG/512/linkedin_black_logo_icon_147114.png" className="mx-auto mt-1 h-6 w-6" aria-hidden="true" />
              </a> :""}
                
              </li>
            ))}
          </ul>
          </div>
        </div>


        {/* Content section */}
        <div className="mx-auto mt-12 max-w-7xl px-6 sm:mt-0 lg:px-8 xl:mt-40">
          <div className="mx-auto max-w-2xl lg:mx-0 lg:max-w-none">
            
            <div className="mt-6 flex flex-col gap-x-8 gap-y-20 lg:flex-row">
              
              <div className="bg-[#F1DDC2] h-3/5 rounded-2xl p-8 lg:w-full lg:max-w-2xl lg:flex-auto">
              <h2 className="text-3xl font-bold tracking-tight text-stone-900 sm:text-4xl">Historien bak Useless</h2>
                <p className="mt-2 text-xl leading-8 text-gray-600">
                Vi fremmer endring gjennom kreativitet.
                </p>
                <div className="mt-8 max-w-xl text-base leading-7 text-gray-700">
                  <p>
                  Ideen om å skape en markedsplass for langsom, håndlaget og selvlaget mote ble virkelig da en gjeng studenter møtte hverandre ved NTNU Entreprenørskole. Etter intensive mulighetsstudier ble forretningsideen utviklet. Siden desember 2022 har teamet begynt ytterligere markedsundersøkelser for å etablere UseLess som en plattform og fellesskap dedikert til å promotere og koble talentfulle individuelle skapere i moteindustrien med bevisste kjøpere, og fremme et blomstrende fellesskap forpliktet til bærekraftige valg.
                  </p>
                  
                </div>

              </div>
              
              
              <div className="lg:flex lg:flex-auto lg:justify-center">
                <dl className="w-64 space-y-8 xl:w-80">
                <h2 className="text-3xl pl-2 tracking-tight text-stone-900 sm:text-4xl">Visste du at ..</h2>
                  {stats.map((stat) => (
                    <div key={stat.label} className="flex flex-col-reverse gap-y-4 p-2 hover:bg-pink-100 hover:shadow hover:rounded-xl">
                      <dt className="text-base leading-7 text-gray-600">{stat.label}</dt>
                      <dd className="text-5xl font-semibold tracking-tight text-stone-900">{stat.value}</dd>
                    </div>
                  ))}
                </dl>
              </div>
            </div>
          </div>
        </div>

        {/* Image section */}
        <div className="mt-32 sm:mt-40 xl:mx-auto xl:max-w-7xl xl:px-8">
          <img
            src={inspoImage}
            alt=""
            className="aspect-[5/2] w-full object-cover xl:rounded-3xl"
          />
        </div>

        {/* Values section */}
        <div className="mx-auto mt-32 max-w-7xl px-6 sm:mt-40 lg:px-8">
          <div className="mx-auto max-w-2xl lg:mx-0">
            <h2 className="text-3xl font-bold tracking-tight text-stone-900 sm:text-4xl">Våre Verdier</h2>
            <p className="mt-6 text-lg leading-8 text-gray-600">
            Det begynte med en gjeng som var lei av å handle fast fashion.
            </p>
          </div>
          <dl className="mx-auto mt-16 grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 text-base leading-7 sm:grid-cols-2 lg:mx-0 lg:max-w-none lg:grid-cols-3">
            {values.map((value) => (
              <div key={value.name}>
                 <img className="ml-20 mb-8 h-40 w-auto rounded-xl" src={value.img} alt="" />
                <dt className="font-semibold text-stone-900">{value.name}</dt>
                <dd className="mt-1 text-gray-600">{value.description}</dd>
              </div>
            ))}
          </dl>
        </div>

        <div className="relative isolate bg-stone-50 mt-20">
      <div className="mx-auto grid max-w-7xl grid-cols-1 lg:grid-cols-2">
        <div className="relative px-6 pb-20 pt-24 sm:pt-32 lg:static lg:px-8 lg:py-48">
          <div className="mx-auto max-w-xl lg:mx-0 lg:max-w-lg">
            <div className="rounded-2xl absolute inset-y-0 left-0 -z-10 w-full overflow-hidden bg-gray-100 ring-1 ring-gray-900/10 lg:w-1/2">
              <svg
                className="absolute inset-0 h-full w-full stroke-gray-200 [mask-image:radial-gradient(100%_100%_at_top_right,white,transparent)]"
                aria-hidden="true"
              >
                <defs>
                  <pattern
                    id="83fd4e5a-9d52-42fc-97b6-718e5d7ee527"
                    width={200}
                    height={200}
                    x="100%"
                    y={-1}
                    patternUnits="userSpaceOnUse"
                  >
                    <path d="M130 200V.5M.5 .5H200" fill="none" />
                  </pattern>
                </defs>
                <rect width="100%" height="100%" strokeWidth={0} fill="white" />
                <svg x="100%" y={-1} className="overflow-visible fill-gray-50">
                  <path d="M-470.5 0h201v201h-201Z" strokeWidth={0} />
                </svg>
                <rect width="100%" height="100%" strokeWidth={0} fill="url(#83fd4e5a-9d52-42fc-97b6-718e5d7ee527)" />
              </svg>
            </div>
            <h2 className="text-3xl font-bold tracking-tight text-stone-900">Kontakt oss</h2>
            <p className="mt-6 text-lg leading-8 text-gray-600">
            Har du spørsmål eller ønsker å utforske muligheten for et samarbeid? Ta gjerne kontakt med oss via e-post eller send en melding her. Vi ser fram til å høre fra deg!
            </p>
            <dl className="mt-10 space-y-4 text-base leading-7 text-gray-600">
              <div className="flex gap-x-4">
                <dt className="flex-none">
                  <span className="sr-only">Address</span>
                  <BuildingOffice2Icon className="h-7 w-6 text-gray-400" aria-hidden="true" />
                </dt>
                <dd>
                  Sem sælands vei 1
                  <br />
                  7030, Trondheim
                </dd>
              </div>
              <div className="flex gap-x-4">
                <dt className="flex-none">
                  <span className="sr-only">Telefon</span>
                  <PhoneIcon className="h-7 w-6 text-gray-400" aria-hidden="true" />
                </dt>
                <dd>
                  <a className="hover:text-stone-900" href="tel:+1 (555) 234-5678">
                    +47 913 97 173
                  </a>
                </dd>
              </div>
              <div className="flex gap-x-4">
                <dt className="flex-none">
                  <span className="sr-only">E-post</span>
                  <EnvelopeIcon className="h-7 w-6 text-gray-400" aria-hidden="true" />
                </dt>
                <dd>
                  <a className="hover:text-stone-900" href="mailto:hello@example.com">
                  hello@uselesshq.com
                  </a>
                </dd>
              </div>
            </dl>
          </div>
        </div>
        <form action="#" method="POST" className="px-6 pb-24 pt-20 sm:pb-32 lg:px-8 lg:py-48">
          <div className="mx-auto max-w-xl lg:mr-0 lg:max-w-lg">
            <div className="grid grid-cols-1 gap-x-8 gap-y-6 sm:grid-cols-2">
              <div>
                <label htmlFor="first-name" className="block text-sm font-semibold leading-6 text-stone-900">
                  Fornavn
                </label>
                <div className="mt-2.5">
                  <input
                    type="text"
                    name="first-name"
                    id="first-name"
                    autoComplete="given-name"
                    className="block w-full rounded-md border-0 px-3.5 py-2 text-stone-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#F36699] sm:text-sm sm:leading-6"
                  />
                </div>
              </div>
              <div>
                <label htmlFor="last-name" className="block text-sm font-semibold leading-6 text-stone-900">
                  Etternavn
                </label>
                <div className="mt-2.5">
                  <input
                    type="text"
                    name="last-name"
                    id="last-name"
                    autoComplete="family-name"
                    className="block w-full rounded-md border-0 px-3.5 py-2 text-stone-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#F36699] sm:text-sm sm:leading-6"
                  />
                </div>
              </div>
              <div className="sm:col-span-2">
                <label htmlFor="email" className="block text-sm font-semibold leading-6 text-stone-900">
                  E-post
                </label>
                <div className="mt-2.5">
                  <input
                    type="email"
                    name="email"
                    id="email"
                    autoComplete="email"
                    className="block w-full rounded-md border-0 px-3.5 py-2 text-stone-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#F36699] sm:text-sm sm:leading-6"
                  />
                </div>
              </div>
              <div className="sm:col-span-2">
                <label htmlFor="phone-number" className="block text-sm font-semibold leading-6 text-stone-900">
                  Telefonnummer
                </label>
                <div className="mt-2.5">
                  <input
                    type="tel"
                    name="phone-number"
                    id="phone-number"
                    autoComplete="tel"
                    className="block w-full rounded-md border-0 px-3.5 py-2 text-stone-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#F36699] sm:text-sm sm:leading-6"
                  />
                </div>
              </div>
              <div className="sm:col-span-2">
                <label htmlFor="message" className="block text-sm font-semibold leading-6 text-stone-900">
                  Beskrivelse
                </label>
                <div className="mt-2.5">
                  <textarea
                    name="message"
                    id="message"
                    rows={4}
                    className="block w-full rounded-md border-0 px-3.5 py-2 text-stone-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#F36699] sm:text-sm sm:leading-6"
                    defaultValue={''}
                  />
                </div>
              </div>
            </div>
            <div className="mt-8 flex justify-end">
              <button
                type="submit"
                className="rounded-md bg-[#F36699] px-3.5 py-2.5 text-center text-sm font-semibold text-white shadow-sm hover:bg-[#F36699] focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-[#F36699]"
              >
                Send melding
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>

        {/* Logo cloud */}
        <div className="relative isolate -z-10 mt-32 sm:mt-48">

          <div className="mx-auto max-w-7xl px-6 lg:px-8">
            <h2 className="text-center text-lg font-semibold leading-8 text-stone-900">
              Våre samarbeidspartnere
            </h2>
            <div className="mx-auto mt-10 grid max-w-lg grid-cols-4 items-center gap-x-8 gap-y-10 sm:max-w-xl sm:grid-cols-4 sm:gap-x-10 lg:mx-0 lg:max-w-none lg:grid-cols-4">
              <img
                className="col-span-2 max-h-12 w-full object-contain lg:col-span-1"
                src={aneo}
                alt="Transistor"
                width={158}
                height={48}
              />
              <img
                className="col-span-2 max-h-12 w-full object-contain lg:col-span-1"
                src={discovery}
                alt="Reform"
                width={158}
                height={48}
              />
              <img
                className="col-span-2 max-h-12 w-full object-contain lg:col-span-1"
                src={AM6}
                alt="Tuple"
                width={158}
                height={48}
              />
              <img
                className="col-span-2 max-h-12  object-contain sm:col-start-2 lg:col-span-1"
                src={brother}
                alt="SavvyCal"
                width={140}
                height={36}
              />
              
            </div>
          </div>
        </div>
      </main>
    </div>
  )
}
