import { useEffect } from 'react'
import { CheckIcon, ClockIcon, ArrowDownCircleIcon } from '@heroicons/react/20/solid'
import { doc, updateDoc, arrayRemove } from "firebase/firestore";
import { db } from '../firebase';
import { useData } from '../DataContext';
import { getFunctions, httpsCallable } from 'firebase/functions';


export default function CheckoutPage() {
    const { userData, userId } = useData();
    const functions = getFunctions(undefined, 'europe-west1'); 

  
    //This function is called when the user clicks the "Betal med kort" button and creates a Stripe Checkout session
    const handlePayment = async () => {
        const createStripeCheckoutFunction = httpsCallable(functions, 'createStripeCheckout');
        console.log('UserData:', userData);

      // this try catch block calls the createStripeCheckout function and redirects the user to the Stripe Checkout page
        try {
            const result = await createStripeCheckoutFunction(userData);
            const checkoutResponse = result.data as { url: string };
            console.log(checkoutResponse)

            const { url } = checkoutResponse;
            
            if (url) {
                window.location.href = url;
            }
        } catch (error) {
            console.error('Feil ved opprettelse av Stripe Checkout session', error);
        }
    };



    const handleRemoveFromCart = async (productToRemove: any) => {
        const userDocRef = doc(db, "users", userId); // Anta at userId er brukerens ID
      
        try {
          await updateDoc(userDocRef, {
            cart: arrayRemove(productToRemove)
          });
          if ( userData.cart.length === 1) {
            window.location.href = "/";
          }
        } catch (error) {
          console.error("Kunne ikke fjerne produktet fra handlekurven", error);
        }
      };

  return (
    <div className="bg-stone-50">

      <main>
        <div className="mx-auto max-w-2xl px-4 py-8 sm:px-6 sm:py-12 lg:px-0">
          <h1 className="text-center text-3xl font-bold tracking-tight text-stone-900 sm:text-4xl mb-12">Handlekurv</h1>

            <section aria-labelledby="cart-heading">
              <h2 id="cart-heading" className="sr-only">
                Items in your shopping cart
              </h2>

              <ul role="list" className="divide-y divide-gray-200 border-b border-t border-gray-200">
                {userData.cart.map((product: any) => (
                  <li key={product.id} className="flex py-6">
                    <div className="flex-shrink-0">
                      <img
                        src={product.photoURLs[0]}
                        className="h-24 w-24 rounded-md object-cover object-center sm:h-32 sm:w-32"
                      />
                    </div>

                    <div className="ml-4 flex flex-1 flex-col sm:ml-6">
                      <div>
                        <div className="flex justify-between">
                          <h4 className="text-sm">
                            <a href={product.href} className="font-medium text-gray-700 hover:text-gray-800">
                              {product.headline}
                            </a>
                          </h4>
                          <p className="ml-4 text-sm font-medium text-stone-900">{product.price} NOK</p>
                        </div>
                        <p className="mt-1 text-sm text-gray-500">{product.about}</p>
                        <p className="mt-1 text-sm text-gray-500">Størrelse - {product.selectedSize}</p>
                      </div>

                      <div className="mt-4 flex flex-1 items-end justify-between">
                        <p className="flex items-center space-x-2 text-sm text-gray-700">
                          {product.stock ? (
                            <CheckIcon className="h-5 w-5 flex-shrink-0 text-green-500" aria-hidden="true" />
                          ) : product.pdfURL
                          ?
                            <ArrowDownCircleIcon className="h-5 w-5 flex-shrink-0 text-green-500" aria-hidden="true" />
                           :(
                            <ClockIcon className="h-5 w-5 flex-shrink-0 text-gray-300" aria-hidden="true" />
                          )
                        }

                        <span>
                        {
                            product.stock 
                            ? 'På lager' 
                            : product.pdfURL 
                            ? 'Lastes ned etter kjøp'
                            : 'Ikke på lager nå - Ta kontakt med creator'
                        }
                        </span>
                        </p>
                        <div className="ml-4">
                          <button type="button" className="text-sm font-medium text-[#F36699] hover:text-[#455745]" onClick={() => handleRemoveFromCart(product)}>
                            <span>Fjern</span>
                          </button>
                        </div>
                      </div>
                    </div>
                  </li>
                ))}
              </ul>
            </section>

            {/* Order summary */}
            <section aria-labelledby="summary-heading" className="mt-10">
              <h2 id="summary-heading" className="sr-only">
                Order summary
              </h2>

              <div>
                <dl className="space-y-4">
                  <div className="flex items-center justify-between">
                    <dt className="text-base font-medium text-stone-900">Totalt</dt>
                    <dd className="ml-4 text-base font-medium text-stone-900">{userData.cart.reduce((total: any, item: any) => {
    return total + (Number(item.price) || 0); // Legger til item.price til total, eller 0 hvis item.price ikke er definert
  }, 0)} NOK</dd>
                  </div>
                </dl>
                {userData.cart.stock?<p className="mt-1 text-sm text-gray-500">Frakt koster 79kr, og blir lagt til ved betaling</p>
                :""}
              </div>

              <div className="mt-10">
                <button
                  className="w-full rounded-md border border-transparent bg-[#F36699] px-4 py-3 text-base font-medium text-white shadow-sm hover:bg-[#455745] focus:outline-none focus:ring-2 focus:ring-[#455745] focus:ring-offset-2 focus:ring-offset-gray-50"
                  onClick={handlePayment}
                >
                  Betal med kort
                </button>
              </div>

              <div className="mt-6 text-center text-sm text-gray-500">
                <p>
                  eller 
                  </p>
                  <a href="/" className="font-medium text-[#F36699] hover:text-[#455745]">
                     Fortsett å handle
                    <span aria-hidden="true"> &rarr;</span>
                  </a>
               
              </div>
            </section>
    
        </div>
      </main>
    </div>
  )
}