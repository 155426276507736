import React, { useState, useEffect } from 'react';
import { useData } from '../../DataContext';
import { doc, updateDoc } from "firebase/firestore";
import { db, storage } from "../../firebase";
import { CheckCircleIcon, ExclamationTriangleIcon } from '@heroicons/react/20/solid'
import { ref as storageRef, uploadBytes, getDownloadURL } from "firebase/storage";

type FormErrors = {
    reason?: string;
    location?: string;
    age?: string;
    socialMedia?: string;
    // Definer andre felt etter behov
  };

export default function CreatorApplication({ onClose }: any) {
    const { userId, userData } = useData();
    const [sow, setSow] = useState(false);
    const [recipe, setRecipe] = useState(false);
    const [reason, setReason] = useState('');
    const [location, setLocation] = useState('');
    const [age, setAge] = useState('');
    const [socialMedia, setSocialMedia] = useState('');
    const [otherComments, setOtherComments] = useState('');
    const [creatorBio, setCreatorBio] = useState('');
    const [errors, setErrors] = useState<FormErrors>({});
    const [imageURL, setImageURL] = useState('');
    const [themeColor, setThemeColor] = useState('#F1DDC2');
    const [accountNumber, setAccountNumber] = useState('');

    useEffect(() => {
      window.location.href = "/profile#application";
    }, []);

const handleImageUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
  const file = event.target.files ? event.target.files[0] : null;
  if (!file) return;

  try {
    const fileRef = storageRef(storage, `creatorImages/${file.name}`);
    const uploadResult = await uploadBytes(fileRef, file);
    const photoURL = await getDownloadURL(uploadResult.ref);
    setImageURL(photoURL);
  } catch (error) {
    console.error("Error uploading image: ", error);
  }
};

    const validate = () => {
  let tempErrors: FormErrors = {};
  if (!reason.trim()) tempErrors.reason = "Årsak er nødvendig";
  if (!location.trim()) tempErrors.location = "Lokasjon er nødvendig";
  if (!age.trim()) tempErrors.age = "Alder er nødvendig";
  if (!socialMedia.trim()) tempErrors.socialMedia = "Sosiale medier er nødvendig";
  // Legg til flere valideringer etter behov
  setErrors(tempErrors);
  return Object.keys(tempErrors).length === 0; // Returnerer true hvis ingen feil
};

    const handleCheckboxChange = (event: any) => {
        const { name, checked } = event.target;
        if (name === 'sow') setSow(checked);
        if (name === 'recipe') setRecipe(checked);
      };
      
      const handleInputChange = (event: any) => {
        const { name, value } = event.target;
        if (name === 'reason') setReason(value);
        if (name === 'location') setLocation(value);
        if (name === 'age') setAge(value);
        if (name === 'socialMedia') setSocialMedia(value);
        if (name === 'otherComments') setOtherComments(value);
        if (name === 'creatorBio') setCreatorBio(value);
        if (name === 'themeColor') setThemeColor(value);
        if (name === 'accountNumber') setAccountNumber(value);
    };
  
    const handleSubmit = async () => {
        if (validate() && userId) {
          const userDocRef = doc(db, "users", userId);
          const creatorApplication = {
            sow,
            recipe,
            reason,
            location,
            age,
            socialMedia,
            otherComments,
            creatorBio,
            imageURL,
            themeColor,
            accountNumber
          };
          try {
            await updateDoc(userDocRef, { creatorApplication });
            onClose(); 
          } catch (error) {
            console.error("Error updating document: ", error);
          }
        }
      };
  
    

    return (
      <div id="application" className="mt-8 bg-stone-200 shadow sm:rounded-lg">
        <div className="px-4 py-5 sm:p-6">
          <h3 className="text-base font-semibold leading-6 text-stone-900">Søknad om å bli creator</h3>
          <div className="mt-2 max-w-xl text-sm text-gray-500">
            <p>
            Bli en av UseLess sine første Creators
            </p>
          </div>


          <div className="grid grid-cols-2 gap-4 my-4">
          <div>
      <label htmlFor="name" className="block text-sm font-medium leading-6 text-stone-900">
        Fullt navn
      </label>
      <div className="mt-2">
        <input
          type="name"
          name="name"
          id="name"
          className="p-2 block w-full rounded-md border-0 py-1.5 text-stone-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#884723] sm:text-sm sm:leading-6"
          disabled
          value={userData.name}
        />
      </div>
    </div>

    <div>
      <label htmlFor="email" className="block text-sm font-medium leading-6 text-stone-900">
        Email
      </label>
      <div className="mt-2">
        <input
          type="email"
          name="email"
          id="email"
          className="p-2 block w-full rounded-md border-0 py-1.5 text-stone-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#884723] sm:text-sm sm:leading-6"
          disabled
          value={userData.email}
        />
      </div>
    </div>
    </div>

    <div className="space-y-2 my-6">
        <div className="relative flex items-start">
          <div className="flex h-6 items-center">
            <input
              id="sow"
              name="sow"
              type="checkbox"
              checked={sow}
              onChange={handleCheckboxChange}
              className="h-4 w-4 rounded border-gray-300 text-[#884723] focus:ring-[#884723]"
            />
          </div>
          <div className="ml-3 text-sm leading-6">
            <label htmlFor="sow" className="font-medium text-stone-900">
              Selger klær
            </label>
          </div>
        </div>
        
        <div className="relative flex items-start">
          <div className="flex h-6 items-center">
            <input
              id="recipe"
              name="recipe"
              type="checkbox"
              checked={recipe}
              onChange={handleCheckboxChange}
              className="h-4 w-4 rounded border-gray-300 text-[#884723] focus:ring-[#884723]"
            />
          </div>
          <div className="ml-3 text-sm leading-6">
            <label htmlFor="recipe" className="font-medium text-stone-900">
              Selger sy-mønstre
            </label>
          </div>
        </div>
        </div>

    <div className="grid grid-cols-2 gap-4 my-4">

    <div>
      <label htmlFor="email" className="block text-sm font-medium leading-6 text-stone-900">
      Hvorfor vil du bli en creator?
      </label>
      <div className="mt-2">
        <input
          type="text"
          name="reason"
          id="reason"
          value={reason}
          onChange={handleInputChange}
          className="p-2 block w-full rounded-md border-0 py-1.5 text-stone-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#884723] sm:text-sm sm:leading-6"
          placeholder="..."
          required
        />
      </div>
    </div>

    <div>
      <label htmlFor="email" className="block text-sm font-medium leading-6 text-stone-900">
      Hvor bor du? (Fylke)
      </label>
      <div className="mt-2">
        <input
          type="text"
          name="location"
          id="location"
          value={location}
          onChange={handleInputChange}
          className="p-2 block w-full rounded-md border-0 py-1.5 text-stone-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#884723] sm:text-sm sm:leading-6"
          placeholder="..."
          required
        />
      </div>
    </div>
    </div>


        <div className="grid grid-cols-2 gap-4 my-4">
    <div>
      <label htmlFor="email" className="block text-sm font-medium leading-6 text-stone-900">
      Alder
      </label>
      <div className="mt-2">
        <input
          type="number"
          name="age"
          id="age"
          value={age}
          onChange={handleInputChange}
          className="p-2 block w-full rounded-md border-0 py-1.5 text-stone-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#884723] sm:text-sm sm:leading-6"
          placeholder="..."
          required
        />
      </div>
    </div>

    <div>
      <label htmlFor="email" className="block text-sm font-medium leading-6 text-stone-900">
      Dine Some-kanaler?
      </label>
      <div className="mt-2">
        <input
          type="text"
          name="socialMedia"
          id="socialMedia"
          value={socialMedia}
          onChange={handleInputChange}
          className="p-2 block w-full rounded-md border-0 py-1.5 text-stone-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#884723] sm:text-sm sm:leading-6"
          placeholder="..."
          required
        />
      </div>
    </div>
    </div>

    <div className="grid grid-cols-2 gap-4 my-4">
    <div>
      <label htmlFor="creatorBio" className="block text-sm font-medium leading-6 text-stone-900">
        Skriv en bio til din shop
      </label>
      <div className="mt-2">
        <textarea
          rows={4}
          name="creatorBio"
          id="creatorBio"
          placeholder="..."
          value={creatorBio}
          onChange={handleInputChange}
          className="p-2 block w-full rounded-md border-0 py-1.5 text-stone-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#884723] sm:text-sm sm:leading-6"
          defaultValue={''}
        />
      </div>
    </div>

    <div>
  <label htmlFor="imageUpload" className="block text-sm font-medium leading-6 text-stone-900">
    Last opp creator bilde
  </label>
  <div className="mt-2">
    <input
      type="file"
      name="imageUpload"
      id="imageUpload"
      className="p-2 block w-full rounded-md border-0 py-1.5 text-stone-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#884723] sm:text-sm sm:leading-6"
      onChange={handleImageUpload}
      accept="image/*"
    />
  </div>
  <label htmlFor="themeColor" className="mt-1 block text-sm font-medium leading-6 text-stone-900">
    Velg temafarge
  </label>
  <div className="mt-1">
    <input
      type="color"
      name="themeColor"
      id="themeColor"
      value={themeColor} // Bind til temafarge state
      onChange={handleInputChange} // Bruk eksisterende input endringshåndterer
      className="p-1 w-1/3 block w-full h-8 rounded-md border-0 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-[#884723] sm:text-sm sm:leading-6"
    />
  </div>
</div>
    </div>

    <div>
      <label htmlFor="otherComments" className="block text-sm font-medium leading-6 text-stone-900">
        Andre kommentarer
      </label>
      <div className="mt-2">
        <textarea
          rows={4}
          name="otherComments"
          id="otherComments"
          value={otherComments}
          onChange={handleInputChange}
          className="p-2 block w-full rounded-md border-0 py-1.5 text-stone-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#884723] sm:text-sm sm:leading-6"
          defaultValue={''}
        />
      </div>
    </div>
    <div>
      <label htmlFor="accountNumber" className="mt-4 block text-sm font-medium leading-6 text-stone-900">
        Kontonummer for utbetalinger
      </label>
      <div className="mt-2">
      <input
          type="text"
          name="accountNumber"
          id="accountNumber"
          value={accountNumber}
          onChange={handleInputChange}
          className="w-1/2 p-2 block w-full rounded-md border-0 py-1.5 text-stone-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#884723] sm:text-sm sm:leading-6"
          defaultValue={''}
          placeholder='...'
        />
      </div>
    </div>

          <div className="mt-8 flex gap-4">
            <button
              type="button"
              className="inline-flex items-center rounded-md bg-[#884723] px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-[#465745] focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-200"
              onClick={handleSubmit}
            >
                <CheckCircleIcon className="w-5 h-5 mr-2"></CheckCircleIcon>
              Send søknad
            </button>
            <button
              type="button"
              className="inline-flex items-center rounded-md bg-[#f1ddc2] px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-[#465745] focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-500"
              onClick={onClose}  
            >
              Lukk
            </button>
            <span className="ml-2 font-light">Ved å søke aksepterer du <a href="/terms" className="font-semibold underline cursor-pointer">terms & Conditions</a> relatert til å være creator</span>
          </div>
          {errors.reason && <span className="error">
          <div className="rounded-md bg-yellow-50 p-4 mt-4">
      <div className="flex">
        <div className="flex-shrink-0">
          <ExclamationTriangleIcon className="h-5 w-5 text-[#F36699]" aria-hidden="true" />
        </div>
        <div className="ml-3">
          <h3 className="text-sm font-medium text-[#F36699]">{errors.reason}</h3>
        </div>
      </div>
    </div>
            </span>}
        </div>
      </div>
    )
  }
  