import React from 'react';
import { EnvelopeIcon, PhoneIcon, ArrowDownCircleIcon, ListBulletIcon } from '@heroicons/react/20/solid'
import { useData } from '../../../DataContext';


export default function RecipeCard({ recipe, onCardClick }: any) {
  const { userData } = useData();

  const handleClick = () => {
    onCardClick(recipe);
  };


  return (
    <>
        <div className="product-card " onClick={handleClick}>
      <div
        key={recipe.id}
        className="cursor-pointer m-4 sm:m-0 group relative flex flex-col overflow-hidden rounded-2xl border border-gray-200 bg-stone-50 hover:shadow hover:shadow-pink-300"
      >
        <div className="bg-gray-200 sm:h-40 w-full overflow-hidden">
          <img
            src={recipe.photoURLs[0]}
            alt={recipe.headline}
            className="h-full w-full object-cover"
          />
        </div>
        <div className="flex flex-1 flex-col space-y-2 p-4">
        <h3 className="text-sm flex justify-between font-medium text-stone-900 w-full">
  {recipe.headline}
  
  <span className="inline-flex items-center rounded-md bg-gray-400/10 px-2 py-1 text-xs font-medium ring-1 ring-inset ring-gray-400/20">{`${recipe.price} kr`}</span>
</h3>

          {/* Bruker line-clamp-3 for å begrense beskrivelsen til 3 linjer */}
          <p className="text-sm text-gray-500 line-clamp-3">{recipe.about}</p>
          <div className="flex flex-1 justify-between justify-end">
          <span className="inline-flex items-center rounded-md bg-pink-100 px-1.5 py-0.5 text-xs font-medium text-pink-700">
          {recipe.mainCategory}
      </span>
      <p className="text-sm text-gray-500">{`Av ${recipe.creatorName}`}</p>
          </div>
        </div>
        {
        userData.recipes?.some((r: any) => r.id === recipe.id) || userData.uid == recipe.creatorId ?     (
          <div className="-mt-px flex divide-x divide-gray-200">
            <a
              href={recipe.pdfURL}
              className="relative hover:bg-gray-100 -mr-px inline-flex w-0 flex-1 items-center justify-center gap-x-3 rounded-bl-lg border border-transparent py-4 text-sm font-semibold text-stone-900"
            >
              <ArrowDownCircleIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
              Last ned PDF
            </a>
          </div>
        ) : ""
      }
      </div>
    </div>
        </>
  )
}