import React, { useState, useEffect } from 'react';
import ListingCard from "./ListingCard"
import ListingDetail from "./ListingDetail"
import AddListing from "./AddListing"
import { PlusIcon, PlayIcon, PauseIcon } from '@heroicons/react/20/solid'
import { useData } from '../../../DataContext';
import { doc, updateDoc } from "firebase/firestore";
import { db } from "../../../firebase";

export default function MyListings() {
    const [selectedListing, setSelectedListing] = useState(null);
    const [isDetailOpen, setIsDetailOpen] = useState(false);
    const [isAddOpen, setIsAddOpen] = useState(false);
    const { listings, userData, userId  } = useData();

    const handleCardClick = (listing: any) => {
        setSelectedListing(listing);
        setIsDetailOpen(true);
    };

    const handleCloseDetail = () => {
        setIsDetailOpen(false);
        setSelectedListing(null);
    };

    const handleAddClick = () => {
      setIsAddOpen(true);
    };

  const handleCloseAdd = () => {
    setIsAddOpen(false);
  };

  const onTogglePublish = async (listing: any) => {
    const listingDocRef = doc(db, "listings", listing.id);
  
    try {
      await updateDoc(listingDocRef, {
        isActive: !listing.isActive
      });
      console.log("Listing publiseringsstatus oppdatert");
      // Du kan også oppdatere tilstanden her for å reflektere endringen umiddelbart i UI
    } catch (error) {
      console.error("Kunne ikke oppdatere listing publiseringsstatus", error);
    }
  };

    return (
        <>


            <main className="relative isolate min-h-full">
            <div className="bg-stone-50 py-6 sm:py-8">
      <div className="mx-auto max-w-7xl text-center sm:text-left">
        <div className="mx-auto max-w-2xl lg:mx-0">
          <p className="text-base font-semibold leading-7 text-[#F36699]">Creator</p>
          <div className="flex">
          <h2 className="mt-2 text-4xl font-bold tracking-tight text-[#8D4304] sm:text-6xl">Mine listings</h2>
      </div>
      <button
        type="button"
        className="mt-4 inline-flex items-center gap-x-1.5 rounded-md bg-[#F36699] px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-[#465745] focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
        onClick={handleAddClick}
      >
        <PlusIcon className="-ml-0.5 h-5 w-5 " aria-hidden="true" />
        Legg til listing
      </button>

          <p className="mt-6 text-lg leading-8 text-gray-600">
            Her kan du legge til, se, redigere og slette dine listings som creator.
          </p>
        </div>
      </div>
    </div>

            <div className="grid grid-cols-1 gap-y-4 sm:grid-cols-2 sm:gap-x-3 sm:gap-y-10 lg:grid-cols-3 lg:gap-x-8">
            {listings.map((listing: any) => (
              <div>
              <button
              onClick={() => onTogglePublish(listing)}
              className="rounded-lg bg-pink-100 hover:bg-pink-50 px-2 my-1">
              {listing.isActive ? "Deaktiver" : "Aktiver"}
            </button>
          <ListingCard key={listing.id} listing={listing} onCardClick={handleCardClick} />
          </div>
        ))}
                </div>
            </main>
            {isDetailOpen && <ListingDetail listing={selectedListing} onClose={handleCloseDetail} />}
            {isAddOpen && <AddListing onClose={()=>handleCloseAdd()} />}
        </>
    );
}
  