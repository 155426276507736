export default function ListingCard({ listing, onCardClick }: any) {

  const handleClick = () => {
    onCardClick(listing);
  };

  return (
    <div className="product-card" onClick={handleClick}>
      <div
        key={listing.id}
        className="cursor-pointer m-4 sm:m-0 group relative flex flex-col overflow-hidden rounded-2xl border border-gray-200 bg-white hover:shadow hover:shadow-pink-300"
      >
        <div className="bg-gray-200 sm:h-56 w-full overflow-hidden">
          <img
            src={listing.photoURLs[0]}
            alt={listing.headline}
            className="h-full w-full object-cover"
          />
        </div>
        <div className="flex flex-1 flex-col space-y-2 p-4">
          <h3 className="text-sm flex justify-between font-medium text-stone-900 w-full">
            {listing.headline}
            <span className="inline-flex items-center rounded-md bg-gray-400/10 px-2 py-1 text-xs font-medium ring-1 ring-inset ring-gray-400/20">{`${listing.price} kr`}</span>
          </h3>
          <p className="text-sm text-gray-500 line-clamp-3">{listing.about}</p>
          <div className="flex justify-center p-4"> 
           {/* <img src={listing.creatorImage} alt="Creator Bilde" className="h-12 w-12 rounded-full object-cover" /> */}
          </div>
          <div className="flex flex-1 justify-between">
            <span className="inline-flex items-center rounded-md bg-pink-100 px-1.5 py-0.5 text-xs font-medium text-pink-700">
              {listing.mainCategory}
            </span>
            <p className="text-sm text-gray-500">{`Av ${listing.creatorName}`}</p>
          </div>
        </div>
      </div>
    </div>
  );
}