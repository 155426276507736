import React, { useEffect, useState } from 'react';
import { collection, query, getDocs, where } from 'firebase/firestore';
import { db } from '../../firebase';
import CreatorCard from '../creator/CreatorCard'; 
import CreatorDetail from '../creator/CreatorDetail'; 
import shirtIcon from '../../images/icons/UseLess Icon T-shirt - Brown.png';
import Slider from "react-slick";


interface CreatorApplication {
  age: string;
  creatorBio: string;
  imageURL: string;
  location: string;
  otherComments: string;
  reason: string;
  recipe: boolean;
  socialMedia: string;
  sow: boolean;
}

interface Creator {
  id: string;
  name: string;
  specialty: string;
  description: string;
  collections: string;
  imageSrc: string;
  imageAlt: string;
  creatorApplication: CreatorApplication; // Inkluderer CreatorApplication som en del av Creator-typen
}


interface CreatorCardProps {
  creator: Creator;
  onCardClick: (creator: Creator) => void;
}

export default function CreatorSection() {
  const [selectedCreator, setSelectedCreator] = useState<Creator | null>(null);
  const [isDetailOpen, setIsDetailOpen] = useState(false);
  const [creators, setCreators] = useState<Creator[]>([]);

  useEffect(() => {
    const fetchCreators = async () => {
      const q = query(collection(db, "users"), where("creator", "==", true), where("creatorIsActive", "==", true));
      const querySnapshot = await getDocs(q);
      const fetchedCreators = querySnapshot.docs.map(doc => {
        const data = doc.data();
        // Sørg for at vi oppfyller CreatorApplication-kravene her
        const creatorApplication: CreatorApplication = {
          age: data.creatorApplication.age,
          creatorBio: data.creatorApplication.creatorBio,
          imageURL: data.creatorApplication.imageURL,
          location: data.creatorApplication.location,
          otherComments: data.creatorApplication.otherComments,
          reason: data.creatorApplication.reason,
          recipe: data.creatorApplication.recipe ?? false, // Bruk ?? operatoren for å håndtere undefined verdier
          socialMedia: data.creatorApplication.socialMedia,
          sow: data.creatorApplication.sow ?? false,
        };
        return {
          id: doc.id,
          name: data.name || "Ukjent",
          specialty: creatorApplication.reason || 'Ingen spesialitet oppgitt',
          description: data.bio || "Ingen beskrivelse tilgjengelig",
          collections: 'Ingen kolleksjoner oppgitt',
          imageSrc: data.profileImage || "fallback-image-url.jpg", // Fallback bilde-URL
          imageAlt: `${data.name || "Ukjent"}'s profilbilde`,
          creatorApplication: creatorApplication, // Pass på at vi inkluderer dette feltet korrekt
        }
      }) as Creator[]; // Type assertion her for å sikre at vi møter forventningene til Creator-typen
      setCreators(fetchedCreators);
    };
  
    fetchCreators();
  }, []);

  const handleCardClick = (creator: Creator) => {
    setSelectedCreator(creator);
    setIsDetailOpen(true);
  };

  const handleCloseDetail = () => {
    setIsDetailOpen(false);
    setSelectedCreator(null);
  };

   

const settings = {
  infinite: creators.length > 1,
  speed: 800,
  slidesToShow: Math.min(2, creators.length),
  slidesToScroll: Math.min(2, creators.length),
  autoplay: creators.length > 1, 
  autoplaySpeed: 3000,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: Math.min(2, creators.length), 
        slidesToScroll: Math.min(2, creators.length),
        infinite: creators.length > 1,
        dots: true
      }
    },
    {
      breakpoint: 800,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: creators.length > 1, 
      }
    }
  ]
};

  return (
    <div className="flex flex-col lg:flex-row bg-[#F1DDC2] px-6 py-12 sm:py-16 lg:px-8">
      <div className="mx-auto max-w-2xl text-center lg:text-left lg:mx-0 lg:flex-1">
        <h2 className="mt-2 text-4xl font-bold tracking-tight text-[#8D4304] sm:text-6xl">Møt noen av våre talentfulle creators!</h2>
        <p className="mt-6 text-lg leading-8 text-gray-600">
          Skaper design som betyr noe.
        </p>
        <img className="w-40 h-40 m-12 mx-auto lg:mx-0" src={shirtIcon} alt="Shirt Icon"></img>
      </div>
      <div className="mx-4 md:w-1/2">
      <Slider {...settings}>
      {creators.map(creator => (
          <div className="p-0 sm:p-4 recipe-card" key={creator.id}>
          <CreatorCard key={creator.id} creator={creator} onCardClick={handleCardClick}/>
          </div>
        ))}
             </Slider>
      </div>
      {isDetailOpen && <CreatorDetail creator={selectedCreator}  onClose={handleCloseDetail} />}
    </div>
  );
}